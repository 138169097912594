import React, { Fragment, useCallback, useEffect, useState } from "react";
import { useRoomConnect } from "../../hooks/useRoomConnect";
import { SwipeButton, CircleButton } from "./styled";
import { CamOnIconButton, CamOffIconButton } from "../../assets/icons";

type IToggleVideoButtonProps = {
  className?: string;
  type: "circle" | "swipe";
  enabled?: boolean;
  pauseRoom?: boolean;
  roomAssets?: any;
};

export const ToggleVideoButton: React.FC<IToggleVideoButtonProps> = (
  props: IToggleVideoButtonProps
) => {
  const [videoEnable, setVideoEnable] = useState<boolean>(false);
  const { enableVideo, disableVideo, videoTrack } = useRoomConnect();
  const [videoEnabledByButtonClick, setVideoEnabledByButtonClick] =
    useState<boolean>(false);

  const enableInternalLocalVideo = useCallback(async () => {
    await enableVideo();
    setVideoEnable(true);
    // eslint-disable-next-line
  }, []);

  const disableInternalLocalVideo = useCallback(async () => {
    if (!videoTrack.name) return;

    await disableVideo();
    setVideoEnable(false);
    // eslint-disable-next-line
  }, []);

  const getVideo = useCallback(async () => {
    if (!videoEnable) {
      await enableInternalLocalVideo();
      setVideoEnabledByButtonClick(true);
    } else {
      await disableInternalLocalVideo();

      setVideoEnabledByButtonClick(false);
    }
    // eslint-disable-next-line
  }, [videoEnable]);

  useEffect(() => {
    if (props.enabled) {
      enableInternalLocalVideo();
      setVideoEnabledByButtonClick(true);
    }

    if (props.enabled === false && videoEnable) {
      disableInternalLocalVideo();
    }
  }, [props.enabled]); //eslint-disable-line

  useEffect(() => {
    if (videoTrack.isEnabled) {
      setVideoEnabledByButtonClick(true);
      enableInternalLocalVideo();
    } else {
      setVideoEnable(false);
    }
  }, [videoTrack]); //eslint-disable-line

  useEffect(() => {
    if (props.pauseRoom) disableInternalLocalVideo();
    console.log(props.pauseRoom, videoEnabledByButtonClick);
    if (props.pauseRoom === false && videoEnabledByButtonClick)
      enableInternalLocalVideo();
  }, [props.pauseRoom]); //eslint-disable-line

  return (
    <Fragment>
      {props.type === "circle" && (
        <CircleButton style={{backgroundColor: props.roomAssets?.buttonColor}} onClick={getVideo}>
          {videoEnable && <CamOnIconButton />}
          {!videoEnable && <CamOffIconButton />}
        </CircleButton>
      )}
      {props.type === "swipe" && (
        <SwipeButton style={{backgroundColor: props.roomAssets?.buttonColor}} videoEnable={videoEnable} onClick={getVideo}>
          <span>Vídeo</span>
          <div>
            {videoEnable && <CamOnIconButton />}
            {!videoEnable && <CamOffIconButton />}
          </div>
        </SwipeButton>
      )}
    </Fragment>
  );
};
