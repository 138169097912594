import React, { Fragment, useCallback, useEffect, useState } from "react";
import { useRoomConnect } from "../../hooks/useRoomConnect";
import { SwipeButton, CircleButton } from "./styled";
import { MicOnIconButton, MicOffIconButton } from "../../assets/icons";

type IToggleAudioButtonProps = {
  className?: string;
  type: "circle" | "swipe";
  enabled?: boolean;
  pauseRoom?: boolean;
  roomAssets?: any;
};

export const ToggleAudioButton: React.FC<IToggleAudioButtonProps> = (
  props: IToggleAudioButtonProps
) => {
  const [audioEnable, setAudioEnable] = useState<boolean>(false);
  const { enableAudio, disableAudio, audioTrack } = useRoomConnect();
  const [audioEnabledByButtonClick, setAudioEnabledByButtonClick] =
    useState<boolean>(false);

  const enableInternalLocalAudio = useCallback(async () => {
    await enableAudio();
    setAudioEnable(true);
    // eslint-disable-next-line
  }, []);

  const disableInternalLocalAudio = useCallback(async () => {
    await disableAudio();
    setAudioEnable(false);
    // eslint-disable-next-line
  }, []);

  const getAudio = useCallback(async () => {
    if (!audioEnable) {
      await enableInternalLocalAudio();
      setAudioEnabledByButtonClick(true);
    } else {
      await disableInternalLocalAudio();
      setAudioEnabledByButtonClick(false);
    }
    // eslint-disable-next-line
  }, [audioEnable]);

  useEffect(() => {
    if (props.enabled) {
      enableInternalLocalAudio();
      setAudioEnabledByButtonClick(true);
    } else {
      disableInternalLocalAudio();
    }
  }, [props.enabled]); //eslint-disable-line

  useEffect(() => {
    if (audioTrack.isEnabled) {
      enableInternalLocalAudio();
      setAudioEnabledByButtonClick(true);
    } else {
      setAudioEnable(false);
    }
  }, [audioTrack]); //eslint-disable-line

  useEffect(() => {
    if (props.pauseRoom) disableInternalLocalAudio();

    if (props.pauseRoom === false && audioEnabledByButtonClick)
      enableInternalLocalAudio();
  }, [props.pauseRoom]); //eslint-disable-line

  return (
    <Fragment>
      {props.type === "circle" && (
        <CircleButton style={{backgroundColor: props.roomAssets?.buttonColor}} onClick={getAudio}>
          <div>
            {audioEnable && <MicOnIconButton />}
            {!audioEnable && <MicOffIconButton />}
          </div>
        </CircleButton>
      )}
      {props.type === "swipe" && (
        <SwipeButton style={{backgroundColor: props.roomAssets?.buttonColor}} audioEnable={audioEnable} onClick={getAudio} {...props}>
          <span>Áudio</span>
          <div>
            {audioEnable && <MicOnIconButton />}
            {!audioEnable && <MicOffIconButton />}
          </div>
        </SwipeButton>
      )}
    </Fragment>
  );
};
