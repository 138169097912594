import styled, { css } from "styled-components";

interface StyleProps {
  pauseEnable?: boolean;
}

export const SwipeButton = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  -webkit-appearance: none;
  appearance: none; // change the behavior of input-checkbox to div
  width: 48px;
  height: 48px;
  background-color: #6229bf;
  border-radius: 50px;
  outline: none; // remove switch's retangle outline
  position: relative;
  transition: all linear 0.2s;
  justify-content: center;
  margin-right: 20px;
  ${(props: { pauseEnable: boolean }) => props.pauseEnable && css``}

  @media (max-width: 360px) {
    width: 130px;
  }

  & > div {
    display: flex;
    align-items: center;
    justify-content: center;
    content: "";
    width: 48px;
    height: 48px;
    background-color: #fff;
    top: 5px;
    border-radius: 50%;
    transition: 200ms;
    ${(props: { pauseEnable: boolean }) =>
      props.pauseEnable &&
      css`
        left: 5px;
      `}
    @media (max-width: 360px) {
      ${(props: { pauseEnable: boolean }) =>
        props.pauseEnable &&
        css`
          left: 81px;
        `}
    }
  }
  span {
    color: #fff;

    font-weight: bold;
    text-shadow: -5px 0px 6px rgba(0, 0, 0, 0.25);
    transition: 200ms;
    ${(props: { pauseEnable: boolean }) =>
      props.pauseEnable
        ? css`
            margin-left: 55px;
          `
        : css`
            margin-right: 55px;
          `}
    @media (max-width: 360px) {
      margin-right: 16%;
      margin-left: 15%;
    }
  }
`;
