import styled from "styled-components";

export const BreakMask = styled.div`
  width: 100vw;
  height: 100vh;
  background: linear-gradient(180deg, #2c1050 -18.72%, #0a070f 75.27%);
  backdrop-filter: blur(15px);
  z-index: 500;
  position: fixed;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  & > div {
    display: flex;
    align-items: center;
    input {
      width: 351px;
      height: 52px;
      border-radius: 35px 0px 0px 35px;
      padding-left: 20px;
      margin-right: -25px;
    }
    button {
      width: 175px;
      height: 52px;
      background: #00ffe6;
      box-shadow: -4px 0px 17px rgba(0, 0, 0, 0.25);
      border-radius: 35px;
      cursor: pointer;
      font-family: Poppins;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: #323232;

      &:hover {
        background: #1b0d34;
        color: #fff;
      }
    }
  }

  h2 {
    font-size: 72px;
    color: #fff;
  }

  h3 {
    font-size: 72px;
    color: #fff;
    font-weight: normal;
  }

  .pessoalize-logo {
    max-width: 177px;
    margin-bottom: 150px;
    border: 2px solid #fff;
    padding: 10px;
  }
`;
