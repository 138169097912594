import React, { useState } from "react";
import { LocalVideoTrack } from "twilio-video";
import { pauseRoomByAttendant } from "../../socket";
import { SwipeButton } from "./styled";

type ITogglePauseButtonProps = { switched: (pauseEnable: boolean) => void; roomAssets: any };

export const TogglePauseButton: React.FC<ITogglePauseButtonProps> = (
  props: ITogglePauseButtonProps
) => {
  const [pauseEnable, setPauseEnable] = useState<boolean>(false);
  // eslint-disable-next-line
  const [videoTrackState, setVideoTrackState] = useState<LocalVideoTrack>();

  return (
    <SwipeButton
      pauseEnable={pauseEnable}
      onClick={() => {
        pauseRoomByAttendant(!pauseEnable, () => {
          props.switched(!pauseEnable);
          setPauseEnable(!pauseEnable);
        });
      }}      
      {...props}
    >
      {pauseEnable ? (
        <>
          <div
            style={{
              backgroundColor: props.roomAssets?.buttonColor,              
            }}
          >
            <svg
              version="1.1"
              id="Camada_1"
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              viewBox="0 0 207.1 239.2"
              width="16px"
            >
              <path d="M7,0l207.1,119.6L7,239.2L7,0z" fill={props.roomAssets?.iconColor} />
            </svg>
          </div>
        </>
      ) : (
        <>
          <div
            style={{
              backgroundColor: props.roomAssets?.buttonColor,              
            }}
          >
            <svg
              width="13"
              height="17"
              viewBox="0 0 13 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="0.71875"
                width="3.4"
                height="17"
                rx="1.7"
                fill={props.roomAssets?.iconColor}
              />
              <rect
                x="8.87891"
                width="3.4"
                height="17"
                rx="1.7"
                fill={props.roomAssets?.iconColor}
              />
            </svg>
          </div>
        </>
      )}
    </SwipeButton>
  );
};
