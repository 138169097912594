import styled, { css } from "styled-components";

interface StyleProps {
  audioEnable?: boolean;
}

export const SwipeButton = styled.div`
  padding: 0 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  -webkit-appearance: none;
  flex-direction: row-reverse;
  appearance: none; // change the behavior of input-checkbox to div
  min-width: 70px;
  width: 19%;
  min-width: 102px;
  padding-right: 8%;
  height: 45px;
  background-color: #d02a3e;
  border-radius: 50px;
  outline: none; // remove switch's retangle outline
  transition: all linear 0.3s;
  margin-right: 8px;
  ${(props: { audioEnable: boolean }) =>
    props.audioEnable &&
    css`
      background-color: #48c664;
      flex-direction: row;
      padding-left: 8%;
      padding-right: 4px;
    `}
  @media (max-width: 768px) {
    width: auto;
    margin-right: 4px;
  }
  & > div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35px;
    height: 35px;
    background-color: #fff;
    border-radius: 50%;
    transition: 200ms;
    box-shadow: -5px 0px 6px rgba(0, 0, 0, 0.25);
    path {
      fill: ${(audioEnable: StyleProps) =>
        audioEnable.audioEnable === false ? "#d02a3e" : "#48c664"};
    }
  }
  & > span {
    color: #fff;
    font-size: 12px;
    font-family: "Poppins";
    font-weight: 700;
    transition: all 3s;
    cursor: pointer;
    text-shadow: -5px 0px 6px rgba(0, 0, 0, 0.25);
  }
`;

export const CircleButton = styled.button`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-appearance: none;
  appearance: none; // change the behavior of input-checkbox to div
  width: fit-content;
  height: 48px;
  width: 48px;
  border-radius: 50px;
  outline: none; // remove switch's retangle outline
  transition: all linear 0.3s;
  border: none;
  background-color: #fff;
  @media (max-width: 768px) {
    width: 48px;
  }
  & > div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    transition: 200ms;
  }
  & > span {
    color: #fff;
    font-size: 12px;
    font-family: "Poppins";
    font-weight: 700;
    transition: all 3s;
    cursor: pointer;
    text-shadow: -5px 0px 6px rgba(0, 0, 0, 0.25);
  }

  img {
    width: 15px;
  }
`;
