import { useCallback, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { useRoomConnect } from "../../hooks/useRoomConnect";
import { request } from "../../services/request";
import { clearSS } from "../../sessionstorage";
import { ToggleAudioButton } from "../ToggleAudioButton";
import { ToggleVideoButton } from "../ToggleVideoButton";
import {
  ButtonContainer,
  DeviceSelectionContainer,
  DeviceSelectionContent,
  LocalVideo,
  LocalVideoLoading,
  LogoArea,
  MatchScreenContent,
  LogoContainer,
} from "./styles";

import { getVisitantDevice } from "../../sessionstorage";
type MatchScreenProps = {
  protocolId: string;
  attendantCanEnterInRoom: boolean;
};

export const MatchScreen: React.FC<MatchScreenProps> = ({
  protocolId,
  attendantCanEnterInRoom,
}: MatchScreenProps) => {
  const history = useHistory();
  const { connectRoom, videoTrack } = useRoomConnect();
  const localVideoRef = useRef<any>();
  const [preRoomAssets, setPreRoomAssets] = useState<any>({});
  const [visitantMobile, setVisitantMobile] = useState<boolean>(false);
  const enterInRoom = async () => {
    try {
      await connectRoom({
        roomId: protocolId,
      });
    } catch {
      clearSS();
      window.location.href = "";
    }

    history.push(`/sala/${protocolId}`);
  };

  useEffect(() => {
    if (attendantCanEnterInRoom) {
      enterInRoom();
    }
  }, [attendantCanEnterInRoom]); //eslint-disable-line

  const getPreRoomAssets = useCallback(async () => {
    const response = await request({
      showLoading: true,
      showSuccessMessage: false,
      method: "GET",
      path: `rooms/get-assets/pre-room-attendant/${protocolId}`,
    });
    if (!response.erro) {
      setPreRoomAssets(response);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (videoTrack && videoTrack.kind) {
      videoTrack.attach(localVideoRef.current);
      let localVideoRefIntoEffect = localVideoRef.current;
      return () => {
        videoTrack.detach(localVideoRefIntoEffect);
      };
    }
  }, [videoTrack]);

  useEffect(() => {
    getPreRoomAssets();
  }, []); //eslint-disable-line

  useEffect(() => {
    const visitanteJson = getVisitantDevice();
    if (visitanteJson) {
      const visitant = JSON.parse(visitanteJson);
      setVisitantMobile(() =>
        visitant.device.type !== "desktop" ? true : false
      );
    }
  }, [getVisitantDevice]);
  return (
    <MatchScreenContent backgroundColor={preRoomAssets?.backgroundColor}>
      <DeviceSelectionContainer>
        {preRoomAssets &&
          preRoomAssets.logo && (
            <LogoContainer>
              <LogoArea>
                <img src={preRoomAssets.logo} alt="logo"></img>
              </LogoArea>
            </LogoContainer>
          )}
        <DeviceSelectionContent isMobile={visitantMobile}>
          {videoTrack !== undefined ? (
            <LocalVideo isMobile={visitantMobile}>
              <video ref={localVideoRef} autoPlay={true} />
            </LocalVideo>
          ) : (
            <LocalVideoLoading></LocalVideoLoading>
          )}
          <ButtonContainer>
            <ToggleAudioButton type="swipe"></ToggleAudioButton>

            <ToggleVideoButton type="swipe" enabled={true}></ToggleVideoButton>
          </ButtonContainer>
        </DeviceSelectionContent>
      </DeviceSelectionContainer>
      <img
        src="https://assets.inclusao.pessoalize.com/all/PessoalizeLobbyLogo.svg"
        alt="logo"
        style={{ borderRadius: "0px" }}
      />
    </MatchScreenContent>
  );
};
