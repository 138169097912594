import styled from "styled-components";

export const InviteParticipantArea = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  position: fixed;
  z-index: 999;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: center;
  background-color: #0000005e;

  @media (max-width: 600px) {
    padding: 30px;
    box-sizing: border-box;
  }
`;

export const InviteParticipantPopup = styled.div`
  width: 100%;
  max-width: 460px;
  position: relative;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.25);
  border-radius: 24px;

  @media (max-width: 600px) {
    margin: 20px;
  }
`;

export const InviteParticipantHeaderPopup = styled.div<{ pageAssets: any }>`
  width: 100%;
  background-color: ${({pageAssets}) => pageAssets ? pageAssets.backgroundColor : "#6229bf"};
  text-align: center;
  height: 87px;
  border-radius: 24px 24px 0 0;
  display: flex;
  align-items: center;
  justify-content: center;

  h1 {
    font-weight: 600;
    font-size: 18px;
    font-family: "Poppins";
    color: ${({pageAssets}) => pageAssets ? pageAssets.textColor : "#fff"};
  }
`;

export const InviteParticipantBodyPopup = styled.div<{ pageAssets: any }>`
  width: 100%;
  height: 100%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: space-between;
  padding: 30px 46px;
  box-sizing: border-box;
  border-radius: 0 0 24px 24px;

  svg path {
    fill:  ${({pageAssets}) => pageAssets ? pageAssets.textColor : "#fff"};
  }

  svg g rect {
    fill:  ${({pageAssets}) => pageAssets ? pageAssets.textColor : "#fff"};
  } 

  input {
    border-radius: 40px;
    background: #efefef;
    width: 100%;
    box-sizing: border-box;
    padding: 13px 24px;
    box-shadow: 0px 4px 6px rgb(0 0 0 / 25%);
    border: 0;
    padding-right: 50px;
  }

  span {
    cursor: pointer;
    width: auto;
    padding: 10px;
    text-align: center;
    border-radius: 60px;
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
  }

  .group-input {
    position: relative;
    margin-bottom: 17px;
  }

  .link-icon {
    position: absolute;
    right: 2px;
    top: 2px;
    cursor: pointer;
  }

  .link-icon svg circle {
    fill: ${({pageAssets}) => pageAssets ? pageAssets.backgroundColor : "#6229bf"};
  }

  .link-icon:hover svg {
    filter: brightness(1.5);
  }

  .copyLinkPass {
    width: 218px;
    height: 52px;
    background:  ${({pageAssets}) => pageAssets ? pageAssets.backgroundColor : "#6229bf"};
    color:  ${({pageAssets}) => pageAssets ? pageAssets.textColor : "#fff"};
    font-family: "Poppins";
    font-size: 14px;
    font-weight: bold;
    border-radius: 50px;
    text-align: left;
    position: relative;
    padding-left: 25px;
    cursor: pointer;
    margin: 15px auto 0;

    &:hover {
      filter: brightness(1.1);
    }

    svg {
      position: absolute;
      right: 18px;      
    }    
  }  

  .feedbackCopy {
    text-align: center;
    font-size: 12px;
    color: #6229bf;
  }
`;
