import { ButtonStyled } from "./styled";
interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  text?: any;
  icon?: string;
  className?: string;
}

export const Button = ({ text, className, icon, ...props }: ButtonProps) => {
  return (
    <ButtonStyled className={`${className}`} {...props}>
      {text}
    </ButtonStyled>
  );
};
