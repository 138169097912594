import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  connect,
  createLocalAudioTrack,
  createLocalVideoTrack,
  LocalAudioTrack,
  LocalVideoTrack,
  VideoTrack,
  AudioTrack,
  Room as TwilioRoom,
  Participant,
} from "twilio-video";
import { request } from "../services/request";
import { getVisitantDevice } from "../sessionstorage/index";
import { useAuth } from "./auth";
import { useStateRef } from "../utils";

type RoomConnectProviderProps = {
  children: ReactNode;
};

type IConnectProps = {
  roomId: string;
};

type IRoomConnectContextData = {
  room: TwilioRoom;
  participants: Array<Participant>;
  enableAudio: () => Promise<void>;
  disableAudio: () => Promise<void>;
  enableVideo: () => Promise<void>;
  disableVideo: () => Promise<void>;
  connectRoom: (props: IConnectProps) => Promise<void>;
  videoTrack: VideoTrack;
  audioTrack: AudioTrack;
};

const RoomConnectContext = createContext<IRoomConnectContextData>(
  {} as IRoomConnectContextData
);

const getWidthHeight = () => {
  let visitantDevice = JSON.parse(getVisitantDevice() ?? "");
  if (visitantDevice && visitantDevice.type === "mobile") {
    // Dezena -> mando fixo pois foi testes que eu fiz e achei legal
    return {
      frameRate: 24,
      width: 500,
      height: 800,
    };
  } else {
    return { width: 1980, height: 1024, frameRate: 24 };
  }
};

export const RoomConnectProvider = ({ children }: RoomConnectProviderProps) => {
  const [participants, setParticipants] = useState<Array<Participant>>([]);
  const [videoTrack, setVideoTrack, videoTrackRef] =
    useStateRef<LocalVideoTrack>({} as LocalVideoTrack);
  const [audioTrack, setAudioTrack, audioTrackRef] =
    useStateRef<LocalAudioTrack>({} as LocalAudioTrack);

  const [room, setRoom] = useState<TwilioRoom>({} as TwilioRoom);

  const enableAudio = async () => {
    if (
      room.name &&
      room.localParticipant.audioTracks.values.length === 0 &&
      audioTrackRef.current.name
    ) {
      await room.localParticipant.publishTrack(audioTrackRef.current);
      audioTrackRef.current.enable();
      return;
    }

    if (audioTrackRef.current && audioTrackRef.current.name) {
      audioTrackRef.current.enable();
      return;
    }

    let createAudioTrack = await createLocalAudioTrack({
      name: encodeURIComponent(`audio-${new Date().getMilliseconds()}`),
    });
    if (room.name) {
      await room.localParticipant.publishTrack(createAudioTrack);
    }

    createAudioTrack.enable();
    setAudioTrack(createAudioTrack);
  };

  const disableAudio = useCallback(async () => {
    let createdAudio = audioTrackRef.current;

    if (createdAudio.name && room) {
      createdAudio.disable();
    }
  }, [audioTrack, audioTrackRef]); //eslint-disable-line

  const enableVideo = async () => {
    if (
      room.name &&
      room.localParticipant.videoTracks.values.length === 0 &&
      videoTrackRef.current.name
    ) {
      await room.localParticipant.publishTrack(videoTrackRef.current, {
        priority: "low",
      });
      videoTrackRef.current.enable();
      return;
    }

    if (videoTrackRef.current && videoTrackRef.current.name) {
      videoTrackRef.current.enable();
      return;
    }

    let createVideoTrack = await createLocalVideoTrack({
      ...getWidthHeight(),
      name: encodeURIComponent(`camera-${new Date().getMilliseconds()}`),
    });
    if (room.name) {
      await room.localParticipant.publishTrack(createVideoTrack, {
        priority: "low",
      });
    }

    createVideoTrack.enable();
    setVideoTrack(createVideoTrack);
  };

  const disableVideo = useCallback(async () => {
    // setEnableDisableVideoUpdate((prevCount) => prevCount + 1);
    let createdVideo = videoTrackRef.current;

    if (createdVideo.name && room) {
      createdVideo.disable();
    }
  }, [videoTrack, videoTrackRef]); //eslint-disable-line

  const { user } = useAuth();
  const connectRoom = async (props: IConnectProps) => {
    const videoToken = await request({
      showLoading: true,
      showSuccessMessage: false,
      method: "POST",
      path: `rooms/video-token`,
      data: {
        identity: `${user._id}-attendant`,
        roomId: props.roomId,
      },
    });

    let tracks: any = [];
    if (videoTrackRef.current.name) tracks.push(videoTrackRef.current);
    if (audioTrackRef.current.name) tracks.push(audioTrackRef.current);

    if (!videoToken.error) {
      let room = await connect(videoToken.token, {
        automaticSubscription: true,
        bandwidthProfile: {
          video: {
            mode: "grid",
            maxSubscriptionBitrate: 2500000,
          },
        },
        maxAudioBitrate: 16000,
        preferredVideoCodecs: [{ codec: "VP8", simulcast: true }],
        networkQuality: { local: 1, remote: 1 },
        tracks: tracks,
      });
      console.log(room);
      setRoom(room);
    }
  };

  useEffect(() => {
    if (room.name) {
      const participantConnected = (participant: Participant) => {
        setParticipants((prevParticipants) => [
          ...prevParticipants,
          participant,
        ]);
      };

      const participantDisconnected = (participant: Participant) => {
        setParticipants((prevParticipants) =>
          prevParticipants.filter((p) => p !== participant)
        );
      };

      room.on("participantConnected", participantConnected);
      room.on("participantDisconnected", participantDisconnected);
      room.participants.forEach(participantConnected);
      room.on("participantDisconnected", function (participant) {
        participantDisconnected(participant);
      });

      return () => {
        setRoom((currentRoom: any) => {
          if (
            currentRoom &&
            currentRoom.localParticipant.state === "connected"
          ) {
            currentRoom.localParticipant.tracks.forEach(function (
              trackPublication: any
            ) {
              trackPublication.track.stop();
            });
            currentRoom.disconnect();
            return null;
          } else {
            return currentRoom;
          }
        });
      };
    }
  }, [room]);

  return (
    <RoomConnectContext.Provider
      value={{
        room,
        participants,
        enableAudio,
        disableAudio,
        enableVideo,
        disableVideo,
        connectRoom,
        videoTrack,
        audioTrack,
      }}
    >
      {children}
    </RoomConnectContext.Provider>
  );
};

export const useRoomConnect = () => {
  const context = useContext(RoomConnectContext);
  return context;
};
