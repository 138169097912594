import { useEffect, useState, useCallback } from "react";
import Timer from "react-compound-timer/build";
import { useHistory } from "react-router-dom";
import PessoalizeLogo from "../../assets/pessoalize/pessoalizeLoginLogo.png";
import PessoalizePurple from "../../assets/pessoalize/pessoalizeLogoSmalPurple.png";
import { BreakScreen } from "../../components/BreakScreen";
import { MatchScreen } from "../../components/MatchScreen";
import { useAuth } from "../../hooks/auth";
import { clearLs } from "../../localStorage";
import { request } from "../../services/request";
import {
  clearSS,
  getAttedantInRoom,
  getProtocolId,
  removeAttedantInRoom,
  removeProtocolId,
  setAttedantInRoom,
  setProtocolId as setSSProtocolId,
  setSSVideoEnable,
  setVisitantDevice,
} from "../../sessionstorage";
import {
  addAttendant,
  attedantCanGoToRoom,
  attendantGoToQueueAgain,
  matchAttendant,
  socket,
  updateSocketId,
  attendantUpdateStatus,
  attendantInfo,
  attendantLogout,
} from "../../socket";
import { getIpData } from "../../utils";
import { FormatNumber } from "../../utils/FormatNumber";
import {
  ExitButton,
  QueueContainer,
  QueueContent,
  QueueHeader,
  QueueSideBar,
  ReactSelectBreak,
  WrapButton,
  TimeContainer,
  StatusText,
  Logo,
} from "./styles";

import dayjs from "dayjs";
export const Queue: React.FC = () => {
  // eslint-disable-next-line
  const { enterprise, user } = useAuth();
  
  const [menuIsOpen, setMenuIsOpen] = useState<boolean>(false);
  const [attendantCanEnterInRoom, setAttendantCanEnterInRoom] =
    useState<boolean>(false);
  const [protocolId, setProtocolId] = useState<string>();
  const [breakTime, setBreakTime] = useState<{
    value: string;
    label: string;
  }>({ value: "", label: "" });

  const [breaks, setBreaks] = useState<[{ value: string; label: string }]>([
    { value: "", label: "" },
  ]);

 
  const [waitingTimer, setWaitingTimer] = useState<number>(0);

  const handleExitButton = async () => {
    const response = await request({
      showLoading: true,
      showSuccessMessage: false,
      method: "DELETE",
      path: `users/logout`,
      data: {
        ip: await getIpData(),
      },
    });

    if (!response.error) {
      attendantLogout();
      clearSS();
      clearLs();
      window.location.reload();
    }
  };

  const handleSetBreak = async (e: any) => {
    const response = await request({
      showLoading: true,
      showSuccessMessage: false,
      method: "POST",
      path: `users/in-break`,
      data: {
        break: e.value,
        label: e.label,
        ip: await getIpData(),
      },
    });

    if (!response.error) {
      setMenuIsOpen(!menuIsOpen);
      setBreakTime(e);
    }
  };

  const getInfoAttendant = useCallback((infos) => {
    if (infos) {
      const today = dayjs();
      const diff = today.diff(dayjs(infos.timeInfo).subtract(3, "hours"));
      setWaitingTimer(diff);
    } else {
      setWaitingTimer(0);
    }
  }, []);

  const checkAuth = useCallback(async ()=>{
    const checked = await request({
      showLoading: true,
      showSuccessMessage: false,
      method: "POST",
      path: `users/auth-user`,
      data: {
        id: user._id
      },
    }, false);

    if (!checked) {
      attendantLogout();
      clearSS();
      clearLs();
      window.location.reload();
    }
   
    
  }, [user])
  useEffect(()=> {
    if (user) {
      checkAuth();
    }
  },[user])
  useEffect(() => {
    setBreaks(enterprise.breaks);
  }, [enterprise]);

  useEffect(() => {
    const protocolId = getProtocolId();
    if (protocolId) {
      setProtocolId(protocolId);
    }

    setTimeout(() => {
      if (socket.id) {
        updateSocketId();
        attendantInfo(getInfoAttendant);
      }
    }, 1500);

    if (!protocolId) {
      if (breakTime.value === "") {
        setTimeout(() => {
          attendantUpdateStatus("Aguardando");
          addAttendant();
        }, 1500);
      }

      matchAttendant((protocolId: string, visitantDevice: string) => {
        setSSVideoEnable("true");
        setProtocolId(protocolId);
        setSSProtocolId(protocolId);
        setVisitantDevice(visitantDevice);
      });
    }

    attendantGoToQueueAgain(() => {
      removeProtocolId();
      removeAttedantInRoom();
      setProtocolId(undefined);
      window.location.reload();
    });

    attedantCanGoToRoom(() => {
      setAttendantCanEnterInRoom(true);
      setAttedantInRoom("true");
    });

    if (getAttedantInRoom()) {
      setAttendantCanEnterInRoom(true);
    }
    // eslint-disable-next-line
  }, [socket]);

  useEffect(() => {
    if (breakTime.value !== "") {
      attendantUpdateStatus(breakTime.value);
    } else {
      if (!attendantCanEnterInRoom) {
        attendantUpdateStatus(null);
      }
    }
  }, [breakTime, attendantCanEnterInRoom]);
  return (
    <QueueContainer>
      {breakTime.value !== "" && <BreakScreen setBreakTime={setBreakTime} />}
      {protocolId && (
        <MatchScreen
          protocolId={protocolId}
          attendantCanEnterInRoom={attendantCanEnterInRoom}
        />
      )}
      <QueueHeader>
        <div className="waiting">
          <StatusText>Aguardando</StatusText>

          <div className="lds-ellipsis">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
        <TimeContainer>
          {waitingTimer ? (
            <Timer
              formatValue={FormatNumber.addZero}
              initialTime={waitingTimer}
            >
              <Timer.Hours />:<Timer.Minutes />:<Timer.Seconds />
            </Timer>
          ) : null}
        </TimeContainer>
      </QueueHeader>
      <QueueSideBar menuIsOpen={menuIsOpen}>
        <div>
          <WrapButton
            data-cy="btnSidebar"
            onClick={() => {
              setMenuIsOpen(!menuIsOpen);
            }}
          >
            {menuIsOpen ? (
              <svg
                width="21"
                height="24"
                viewBox="0 0 21 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M20.0607 13.0607C20.6464 12.4749 20.6464 11.5251 20.0607 10.9393L10.5147 1.3934C9.92893 0.807613 8.97919 0.807613 8.3934 1.3934C7.80761 1.97919 7.80761 2.92893 8.3934 3.51472L16.8787 12L8.3934 20.4853C7.80761 21.0711 7.80761 22.0208 8.3934 22.6066C8.97918 23.1924 9.92893 23.1924 10.5147 22.6066L20.0607 13.0607ZM-2.62268e-07 13.5L19 13.5L19 10.5L2.62268e-07 10.5L-2.62268e-07 13.5Z"
                  fill="black"
                />
              </svg>
            ) : (
              <svg
                width="21"
                height="24"
                viewBox="0 0 21 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.93934 10.9393C0.353554 11.5251 0.353554 12.4749 0.939341 13.0607L10.4853 22.6066C11.0711 23.1924 12.0208 23.1924 12.6066 22.6066C13.1924 22.0208 13.1924 21.0711 12.6066 20.4853L4.12132 12L12.6066 3.51472C13.1924 2.92893 13.1924 1.97919 12.6066 1.3934C12.0208 0.807612 11.0711 0.807612 10.4853 1.3934L0.93934 10.9393ZM21 10.5L2 10.5L2 13.5L21 13.5L21 10.5Z"
                  fill="black"
                />
              </svg>
            )}
          </WrapButton>
          <QueueContent>
            <img src={PessoalizePurple} alt="Pessoalize Logo" />
            <ReactSelectBreak
              id="break"
              classNamePrefix={"Select"}
              options={breaks}
              placeholder="Selecione sua pausa"
              onChange={(e: any) => handleSetBreak(e)}
              value={breakTime.value !== "" && breakTime}
            />
            <ExitButton onClick={handleExitButton} data-cy="btnLogout">
              Sair
              <svg
                width="19"
                height="19"
                viewBox="0 0 19 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <line
                  y1="-1.5"
                  x2="22.0952"
                  y2="-1.5"
                  transform="matrix(0.689655 0.724138 -0.689655 0.724138 1 3)"
                  stroke="white"
                  strokeWidth="3"
                />
                <line
                  y1="-1.5"
                  x2="22.0952"
                  y2="-1.5"
                  transform="matrix(-0.689655 0.724138 0.689655 0.724138 18 3)"
                  stroke="white"
                  strokeWidth="3"
                />
              </svg>
            </ExitButton>
          </QueueContent>
        </div>
      </QueueSideBar>
      <Logo src={PessoalizeLogo} alt="Pessoalize" />
    </QueueContainer>
  );
};
