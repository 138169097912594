import {
  SideBarArea,
  WrapButton,
  SideBarContent,
  HeaderLogo,
  TabbingInputContainer,
  RequiredMessage,
  FinalizeButton,
  LabelTabbing,
} from "./styles";
import { useEffect, useState } from "react";
import Select from "react-select";

interface ISideBar {
  menuIsOpen: boolean;
  setMenuIsOpen: Function;
  roomAssets: any;
  setTabbingFields: Function;
  setDynamicFields: Function;
  tabbingFields: Array<any>;
  queryParams: any;
  loaderButton: boolean;
  tabbingError: any;
  setShowContactAgain: Function;
  handleFinalizeButton: Function;
  contactAgain: any;
  allOption: any;
}

const customStyles = {
  input: (provided: any) => ({
    ...provided,
    width: "100%",
  }),
  control: (provided: any) => ({
    ...provided,
    width: "100%",
  }),
};

export const SideBar: React.FC<ISideBar> = ({
  loaderButton,
  menuIsOpen,
  queryParams,
  roomAssets,
  setMenuIsOpen,
  setTabbingFields,
  setDynamicFields,
  tabbingError,
  setShowContactAgain,
  handleFinalizeButton,
  contactAgain,
  allOption,
}: ISideBar) => {
  const [optionGroups, setOptionGroups] = useState([]);
  const [optionSubGroups, setOptionSubGroups] = useState([]);
  const [optionReason, setOptionReason] = useState([]);
  const [dynamicFieldsValues, setDynamicFieldsValues] = useState<any>({});

  const handlerChange = (value: any, field: string) => {
    if (field === "groups") {
      const find = allOption?.subgroup?.filter((el: any) => el.groupId === value);
      const list = find.map((el: any) => ({ value: el.id, label: el.label }));
      setOptionSubGroups(list);
    }
    if (field === "subgroup") {
      const find = allOption?.reason?.filter(
        (el: any) => el.subGroupId === value
      );
      const list = find?.map((el: any) => ({ value: el.id, label: el.label }));
      setOptionReason(list);
    }

    if (field === "reason") {
      const reason = allOption?.reason?.find((el: any) => el.id === value);

      const status = allOption?.statusType?.find(
        (el: any) => el.id === reason.statusId
      );
      const groups = allOption?.groups?.find(
        (el: any) => el.id === reason.groupId
      );
      const subGroups = allOption?.subgroup?.find(
        (el: any) => el.id === reason.subGroupId
      );

      const response = {
        reason,
        status,
        groups,
        subGroups,
      };
      
      setTabbingFields(response);
    }
  };

  useEffect(() => {
    if (allOption) {
      const groups = allOption?.groups
        ? allOption.groups.map((el: any) => ({
            value: el?.id,
            label: el?.label,
          }))
        : [];

      const reason = allOption?.reason
        ? allOption.reason.map((el: any) => ({
            value: el?.id,
            label: el?.label,
          }))
        : [];

      setOptionGroups(groups);
      setOptionReason(reason);
    }
  }, [allOption]);
  return (
    <SideBarArea menuIsOpen={menuIsOpen}>
      <WrapButton
          onClick={() => {
            setMenuIsOpen(!menuIsOpen);
          }}
        >
          {!menuIsOpen ? (
            <svg
              width="21"
              height="24"
              viewBox="0 0 21 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.93934 10.9393C0.353554 11.5251 0.353554 12.4749 0.939341 13.0607L10.4853 22.6066C11.0711 23.1924 12.0208 23.1924 12.6066 22.6066C13.1924 22.0208 13.1924 21.0711 12.6066 20.4853L4.12132 12L12.6066 3.51472C13.1924 2.92893 13.1924 1.97919 12.6066 1.3934C12.0208 0.807612 11.0711 0.807612 10.4853 1.3934L0.93934 10.9393ZM21 10.5L2 10.5L2 13.5L21 13.5L21 10.5Z"
                fill="black"
              />
            </svg>
          ) : (
            <svg
              width="21"
              height="24"
              viewBox="0 0 21 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M20.0607 13.0607C20.6464 12.4749 20.6464 11.5251 20.0607 10.9393L10.5147 1.3934C9.92893 0.807613 8.97919 0.807613 8.3934 1.3934C7.80761 1.97919 7.80761 2.92893 8.3934 3.51472L16.8787 12L8.3934 20.4853C7.80761 21.0711 7.80761 22.0208 8.3934 22.6066C8.97918 23.1924 9.92893 23.1924 10.5147 22.6066L20.0607 13.0607ZM-2.62268e-07 13.5L19 13.5L19 10.5L2.62268e-07 10.5L-2.62268e-07 13.5Z"
                fill="black"
              />
            </svg>
          )}
        </WrapButton>
      <div>
        <SideBarContent>
          {roomAssets && roomAssets.backgroundColor && roomAssets.logo && (
            <HeaderLogo backgroundLogoColor={roomAssets.backgroundColor}>
              <img src={roomAssets.logo} alt="logo"></img>
            </HeaderLogo>
          )}
          <TabbingInputContainer>
            <h3 color={roomAssets?.textColor} data-cy="TabbingTitle">Tabulação</h3>
            {tabbingError && <RequiredMessage>{tabbingError}</RequiredMessage>}

            {allOption ? (
              <div className="tabbing-content">
                <LabelTabbing>Grupo</LabelTabbing>
                <Select
                  data-cy={"tb-groups"}
                  placeholder={"Opcional"}
                  styles={customStyles}
                  classNamePrefix="react-select"
                  options={optionGroups}
                  onChange={(e: any) => handlerChange(e.value, "groups")}
                />{" "}
                <LabelTabbing>SubGrupo</LabelTabbing>
                <Select
                  data-cy={"tb-subgroups"}
                  placeholder={"Opcional"}
                  styles={customStyles}
                  classNamePrefix="react-select"
                  options={optionSubGroups}
                  onChange={(e: any) => handlerChange(e.value, "subgroup")}
                />
                <LabelTabbing>Motivo</LabelTabbing>
                <Select
                  data-cy={"tb-reason"}
                  placeholder={"* Obrigatório"}
                  styles={customStyles}
                  classNamePrefix="react-select"
                  options={optionReason}
                  onChange={(e: any) => handlerChange(e.value, "reason")}
                />
              </div>
            ) : null}

            <hr />
          </TabbingInputContainer>

          {roomAssets && roomAssets.clientInformationShow && (
            <>
              <TabbingInputContainer>
                <h3>Informações do cliente</h3>
                <div className="tabbing-content">
                  {roomAssets.clientInformationShow &&
                    roomAssets.clientInformationShow.map((tabbing: any) => {
                      return (
                        <div key={tabbing.label}>
                          <p>{tabbing.label}</p>
                          <input
                            type="text"
                            onChange={(event) => {
                              console.log(dynamicFieldsValues);
                              setDynamicFieldsValues({ ...dynamicFieldsValues, [tabbing.label]: event.target.value });
                              setDynamicFields({ ...dynamicFieldsValues, [tabbing.label]: event.target.value });
                            }}
                          ></input>
                        </div>
                      );
                    })}
                </div>
              </TabbingInputContainer>
              <hr />
            </>
          )}

          <FinalizeButton
          style={{
            backgroundColor: roomAssets?.buttonColor,
            color: roomAssets?.textColor,
          }}
            data-cy="btnFinalize"
            onClick={async () => {
              setDynamicFields(dynamicFieldsValues);

              if (
                roomAssets.contactAgainMessage &&
                contactAgain === undefined
              ) {
                setShowContactAgain(true);
              } else {
                handleFinalizeButton();
              }
            }}
            disabled={loaderButton}
          >
            {loaderButton ? (
              <div className="waiting-text">
                Aguarde<div id="spinner"></div>
              </div>
            ) : (
              <div>
                Finalizar
                <svg
                  width="22"
                  height="17"
                  viewBox="0 0 22 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    y="9.8457"
                    width="3.4"
                    height="9.70226"
                    rx="1.7"
                    transform="rotate(-45 0 9.8457)"
                    fill={roomAssets?.textColor || "white"}
                  />
                  <rect
                    x="19.0938"
                    width="3.4"
                    height="20.2264"
                    rx="1.7"
                    transform="rotate(45 19.0938 0)"
                    fill={roomAssets?.textColor || "white"}
                  />
                </svg>
              </div>
            )}
          </FinalizeButton>
        </SideBarContent>
      </div>
    </SideBarArea>
  );
};
