import styled from "styled-components";
import iconCall from "../../assets/images/call.svg";
import iconChat from "../../assets/images/chat.svg";
import iconChatGreen from "../../assets/images/chat-green.svg";

export const ButtonStyled = styled.button`
  padding: 15px 30px;
  background: #fff;
  border-radius: 30px;
  border: 0;
  font-size: 14px;
  font-family: "Poppins";
  font-weight: 700;
  transition: all 0.3s;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    opacity: 0.8;
  }

  &.finish-call {
    background: #d02a3e url(${iconCall}) no-repeat 110px;
    color: #fff;
    padding-right: 70px;
    box-shadow: 0px 6px 4px rgba(0, 0, 0, 0.25);
    border-radius: 30px;
    height: 54.3px;
  }

  &.open-chat {
    z-index: 9;
    width: 48px;
    height: 48px;
    padding: 0;

    &.chatOpened {
      background-size: 18px;
    }
  }
`;
