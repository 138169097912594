import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`

  #root {
    height: 100%;
    touch-action: pan-y;
  }

  body ,html{
    touch-action: pan-y;
    margin: 0;
    padding: 0;
    font-family: Poppins, Helvetica, Sans-Serif;
    box-sizing:border-box;
    height: 100%;
  }

  h1,h2,h3,h4,h5,h6,p,span{
    margin: 0;
    padding:0;
  }
  button , input , textarea{
    padding:0;
    margin:0;
    outline: none;
    border:none;
  }

  .logo-client {
    max-width: 200px;
    max-height: 100px;
  }
`;

export default GlobalStyle;
