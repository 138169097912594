import styled from "styled-components";

export const LoginContainer = styled.div`
  width: 100vw;
  height: 100vh;
  background: linear-gradient(180deg, #2c1050 -18.72%, #0a070f 75.27%);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LoginContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 50vw;
  height: 50vh;

  input {
    width: 80%;
    height: 40px;
    margin-bottom: 8px;
    border-radius: 50px;
    padding-left: 24px;
    max-width: 450px;
    box-sizing: border-box;

    &::placeholder {
      color: #a6a6a6;
    }
  }

  button {
    height: 40px;
    margin-bottom: 8px;
    max-width: 450px;
    width: 100%;
    border-radius: 50px;
    background: #1b0d34;
    color: #fff;
    border: 1px solid #fff;
    margin-top: 30px;
    font-weight: bold;
    cursor: pointer;

    &:hover {
      background: #01f7e0;
      color: #1b0d34;
    }
    &:focus {
      opacity: 0.5;
    }
  }
`;

export const LoginHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  img {
    margin-bottom: 32px;
  }
  h3 {
    color: #fff;
    margin-bottom: 8px;
  }
`;
