import { Fragment, useEffect, useState } from "react";
import iconFile from "../../assets/images/file.svg";
import { useAuth } from "../../hooks/auth";
import { getProtocolId } from "../../sessionstorage";
import {
  addMessageIntoChat,
  chatMessages,
  getChatMessages,
  requestFileByAttendant,
} from "../../socket";
import {
  ChatStyled,
  FileRequestButton,
  FileuploadArea,
  FileIconArea,
} from "./styled";

interface ChatProps {
  toggleChat: Boolean;
  setToggleChat: Function;
  className: string;
  roomAssets?: any;
}

interface IMessages {
  type: "attendant" | "visitant";
  msg: string;
  _id: string;
  messageType: "MESSAGE" | "ATTACHMENT";
  filename?: string;
}

export const ChatBox = ({
  toggleChat,
  setToggleChat,
  roomAssets,
  ...props
}: ChatProps) => {
  useEffect(() => {}, [toggleChat]);
  const auth = useAuth();
  const [message, setMessage] = useState<string>("");
  const [messages, setMessages] = useState<Array<IMessages>>([]);
  const [loaderButton, setLoaderButton] = useState<boolean>(false);
  const [waitinggFile, setWaitingFile] = useState<boolean>(false);
  const onEnterChat = (e: any) => {
    if (e.keyCode === 13 && e.shiftKey === false) {
      e.preventDefault();
      handleSubmit(e);
    }
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (message === "") return;
    setLoaderButton(true);
    addMessageIntoChat(
      "attendant",
      getProtocolId() ?? "",
      message,
      auth.getPayload()._id ?? "",
      (chatMessages: Array<any>) => {
        setMessages(chatMessages);
        const chatDiv = document.getElementById("chat") as HTMLDivElement;
        if (chatDiv) chatDiv.scrollTop = chatDiv.scrollHeight;
        setLoaderButton(false);
      }
    );
    setMessage("");
  };

  const setMessagesInFront = (chatMessages: Array<any>) => {
    setMessages(() => chatMessages);

    const chatDiv = document.getElementById("chat") as HTMLDivElement;
    if (chatDiv) chatDiv.scrollTop = chatDiv.scrollHeight;
  };

  useEffect(() => {
    chatMessages((chatMessages: Array<any>) => {
      setMessagesInFront(chatMessages);
      setToggleChat(true);
    });

    getChatMessages((chatMessages: Array<any>) => {
      setMessagesInFront(chatMessages);
    });
  }, []); //eslint-disable-line

  useEffect(() => {
    if (waitinggFile) {
      requestFileByAttendant((res: boolean) => {});
    }
  }, [waitinggFile]);

  useEffect(() => {
    if (messages) {
      const lastMsg = messages.slice(-1).pop();

      if (lastMsg?.messageType === "ATTACHMENT" && waitinggFile) {
        setWaitingFile(() => false);
      }
    }
  }, [messages]);

  return (
    <ChatStyled roomAssets={roomAssets} {...props}>
      {toggleChat && (
        <div className="window">
          <span
            className="closeChat"
            onClick={() => setToggleChat(false)}
          ></span>
          <div className="chat" id="chat">
            {messages.map((message) => {
              if (message.messageType === "MESSAGE") {
                return (
                  <Fragment key={message._id}>
                    <div
                      className="chat_message"
                      data-user={
                        message.type === "attendant" ? "me" : "visitant"
                      }
                    >
                      <div
                        style={{
                          background:
                            message.type === "attendant"
                              ? roomAssets.chatBalloonColor
                              : roomAssets.visitantChatBalloonColor,
                          color: roomAssets.textColor || "#fff",
                        }}
                        className="chat_content"
                      >
                        <span>{message.msg}</span>
                      </div>
                    </div>
                  </Fragment>
                );
              }
              if (message.messageType === "ATTACHMENT") {
                return (
                  <a href={message.msg} target="blank" key={message._id}>
                    <div
                      className="chat_message"
                      data-user={
                        message.type === "attendant" ? "me" : "visitant"
                      }
                    >
                      <div className="chat_content">
                        <span>
                          <img src={iconFile} alt="chat" />
                          {message?.filename}
                        </span>
                      </div>
                    </div>
                  </a>
                );
              }
              return null;
            })}
          </div>

          {waitinggFile && (
            <FileuploadArea htmlFor="file">
              <FileIconArea
                style={{
                  backgroundColor: roomAssets.buttonColor,
                }}
              >
                <img src={iconFile} alt="chat" />
              </FileIconArea>
              <span>Aguardando anexo...</span>
            </FileuploadArea>
          )}

          <form
            className="new-message"
            onSubmit={handleSubmit}
            id="new-message"
          >
            <textarea
              className="new-message_input"
              name="message"
              value={message}
              placeholder={loaderButton ? "Enviando Mensagem..." : ""}
              onChange={(e) => setMessage(e.currentTarget.value)}
              onKeyDown={onEnterChat}
              data-cy="inputChat"
            ></textarea>
            <button
              className={"new-message_submit"}
              type="submit"
              onSubmit={handleSubmit}
              disabled={loaderButton}
              data-cy="btnSendChat"
            >
              <svg width="23" height="20" viewBox="0 0 23 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M22.9257 0.794751C23.0348 0.286914 22.5369 -0.141288 22.0513 0.0440318L0.984278 8.0862C0.730731 8.18301 0.563026 8.42602 0.562501 8.6974C0.561977 8.96883 0.728721 9.21249 0.981918 9.31027L6.90003 11.5959V18.7951C6.90003 19.0988 7.10856 19.3627 7.40402 19.433C7.69752 19.5028 8.00356 19.3631 8.14115 19.0902L10.5888 14.233L16.562 18.666C16.9254 18.9356 17.4476 18.7641 17.5799 18.3308C23.1569 0.0454748 22.9158 0.840687 22.9257 0.794751ZM17.7254 3.09892L7.46272 10.4076L3.04705 8.70225L17.7254 3.09892ZM8.21126 11.4843L17.1568 5.11361C9.45928 13.234 9.8613 12.8066 9.82773 12.8518C9.77786 12.9189 9.91449 12.6574 8.21126 16.0373V11.4843ZM16.5969 17.059L11.339 13.157L20.8459 3.12777L16.5969 17.059Z" fill={ roomAssets?.iconColor || "white" }/>
              </svg>
            </button>
            {roomAssets.showFileUploadButton && (
              <FileRequestButton
                bgColor={roomAssets?.buttonColor}
                onClick={() => {
                  setWaitingFile(() => true);
                }}
              >
                <button style={{ backgroundColor: roomAssets?.buttonColor || "#fff" }}>
                <svg width="8" height="17" viewBox="0 0 8 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M4.00002 0C1.79471 0 0 2.06762 0 4.60971V13.3369C0 13.6484 0.21874 13.9005 0.48913 13.9005C0.75934 13.9005 0.97808 13.6484 0.97808 13.3369V4.60971C0.97808 2.68951 2.33343 1.12718 4.00002 1.12718C5.66678 1.12718 7.0221 2.68951 7.0221 4.60971V13.6072C7.0221 14.8563 6.14004 15.8729 5.05591 15.8729C5.04786 15.8729 5.04061 15.8778 5.03258 15.8783C5.02399 15.8778 5.01727 15.8729 5.00871 15.8729C3.92467 15.8729 3.04261 14.8563 3.04261 13.6072V8.21001C3.04261 7.60129 3.47189 7.10656 4.00002 7.10656C4.52826 7.10656 4.95757 7.60129 4.95757 8.21001V13.3369C4.95757 13.6484 5.17669 13.9005 5.44655 13.9005C5.71634 13.9005 5.93565 13.6484 5.93565 13.3369V8.21001C5.93565 6.97995 5.0674 5.97941 4.00002 5.97941C2.93278 5.97941 2.06453 6.97999 2.06453 8.21001V13.6072C2.06453 15.4777 3.385 17 5.00868 17C5.01724 17 5.02396 16.995 5.03255 16.9945C5.04061 16.995 5.04786 17 5.05588 17C6.67917 17 8 15.4777 8 13.6072V4.60971C8.00003 2.06762 6.20535 0 4.00002 0Z" fill={ roomAssets?.iconColor || "#622ABF" } />
                </svg>
                </button>
              </FileRequestButton>
            )}
          </form>
        </div>
      )}
    </ChatStyled>
  );
};
