import { useState } from "react";
import { getProtocolId } from "../../sessionstorage";
import {
  InviteParticipantArea,
  InviteParticipantBodyPopup,
  InviteParticipantHeaderPopup,
  InviteParticipantPopup,
} from "./styles";

type InviteParticipantProps = {
  clientLink: string;
  password: string;
  setShow: Function;
  roomAssets: any;
};

export const InviteParticipant: React.FC<InviteParticipantProps> = ({
  clientLink,
  password,
  setShow,
  roomAssets,
}: InviteParticipantProps) => {
  const [copyText, setCopyText] = useState("");

  return (
    <InviteParticipantArea>
      <InviteParticipantPopup>
        <InviteParticipantHeaderPopup
          pageAssets={{
            backgroundColor: roomAssets?.backgroundColor,
            color: roomAssets?.textColor
          }}
        >
          <h1 data-cy="titleInvite">Convidar participante</h1>
        </InviteParticipantHeaderPopup>
        <InviteParticipantBodyPopup
          pageAssets={{
            backgroundColor: roomAssets?.buttonColor,
            color: roomAssets?.textColor
          }}
        >
          <div className="group-input">
            <input
              type="text"
              defaultValue={clientLink.replace(
                "{protocolId}",
                getProtocolId() ?? ""
              )}
              disabled
            />
            <div
              data-cy="btnCopyLink"
              className="link-icon"
              onClick={() => {
                navigator.clipboard.writeText(
                  `${clientLink.replace("{protocolId}", getProtocolId() ?? "")}`
                );
                setCopyText("Link copiado com sucesso");
              }}              
            >
              <svg
                width="38"
                height="38"
                viewBox="0 0 38 38"                
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="18.6292" cy="19.0667" r="18.6292" />
                <path
                  d="M18.6898 21.678L16.5471 23.8207C16.5471 23.8207 16.5471 23.8207 16.5471 23.8207C16.5471 23.8207 16.5471 23.8208 16.547 23.8208C15.661 24.7069 14.2192 24.7069 13.3331 23.8208C12.9038 23.3915 12.6675 22.8208 12.6675 22.2138C12.6675 21.6068 12.9038 21.0362 13.3329 20.6069C13.333 20.6068 13.333 20.6068 13.3331 20.6067L15.4757 18.4641C15.7715 18.1682 15.7715 17.6885 15.4757 17.3927C15.1799 17.0969 14.7002 17.0969 14.4043 17.3927L12.2617 19.5354C12.2616 19.5356 12.2614 19.5358 12.2613 19.5359C11.5462 20.2512 11.1523 21.2023 11.1523 22.2138C11.1523 23.2255 11.5463 24.1767 12.2618 24.8921C13.0002 25.6305 13.9701 25.9998 14.9401 25.9998C15.9101 25.9998 16.8801 25.6305 17.6184 24.8921V24.8921C17.6185 24.8921 17.6185 24.892 17.6185 24.892L19.7611 22.7494C20.0569 22.4536 20.0569 21.9739 19.761 21.678C19.4653 21.3822 18.9857 21.3822 18.6898 21.678Z"                  
                />
                <path
                  d="M25.155 15.786C25.155 14.7742 24.761 13.823 24.0455 13.1076C22.5687 11.6308 20.1656 11.6308 18.6889 13.1076C18.6888 13.1077 18.6887 13.1078 18.6887 13.1079L16.5461 15.2504C16.2503 15.5462 16.2503 16.0259 16.5461 16.3217C16.6941 16.4697 16.8879 16.5436 17.0818 16.5436C17.2756 16.5436 17.4696 16.4696 17.6174 16.3217L19.76 14.1792C19.7601 14.1791 19.7602 14.179 19.7603 14.1789C20.6463 13.2929 22.0881 13.2928 22.9742 14.1789C23.4034 14.6082 23.6399 15.179 23.6399 15.786C23.6399 16.3929 23.4035 16.9635 22.9744 17.3928C22.9743 17.3929 22.9743 17.3929 22.9742 17.393L20.8316 19.5356C20.5358 19.8314 20.5358 20.3111 20.8316 20.607C20.9796 20.7549 21.1734 20.8289 21.3673 20.8289C21.5612 20.8289 21.755 20.7549 21.903 20.607L24.0456 18.4643C24.0457 18.4642 24.0459 18.464 24.046 18.4638C24.7611 17.7485 25.155 16.7974 25.155 15.786Z"                  
                />
                <path
                  d="M15.4758 21.6781C15.6237 21.826 15.8176 21.9 16.0114 21.9C16.2053 21.9 16.3992 21.826 16.5471 21.6781L20.8325 17.3928C21.1283 17.097 21.1283 16.6173 20.8325 16.3215C20.5367 16.0257 20.057 16.0257 19.7611 16.3215L15.4758 20.6067C15.1799 20.9026 15.1799 21.3823 15.4758 21.6781Z"                  
                />
              </svg>
            </div>
          </div>
          <div className="group-input group-pass">
            <input
              className="input-link"
              type="text"
              disabled
              defaultValue={password}
            />
            <div
            data-cy="btnCopyPassword"
              className="link-icon"
              onClick={() => {
                navigator.clipboard.writeText(`${password}`);
                setCopyText("Senha copiada com sucesso");
              }}
            >
              <svg
                width="38"
                height="38"
                viewBox="0 0 38 38"                
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="18.6292" cy="19.0667" r="18.6292" fill="#6229BF" />
                <path
                  d="M18.6898 21.678L16.5471 23.8207C16.5471 23.8207 16.5471 23.8207 16.5471 23.8207C16.5471 23.8207 16.5471 23.8208 16.547 23.8208C15.661 24.7069 14.2192 24.7069 13.3331 23.8208C12.9038 23.3915 12.6675 22.8208 12.6675 22.2138C12.6675 21.6068 12.9038 21.0362 13.3329 20.6069C13.333 20.6068 13.333 20.6068 13.3331 20.6067L15.4757 18.4641C15.7715 18.1682 15.7715 17.6885 15.4757 17.3927C15.1799 17.0969 14.7002 17.0969 14.4043 17.3927L12.2617 19.5354C12.2616 19.5356 12.2614 19.5358 12.2613 19.5359C11.5462 20.2512 11.1523 21.2023 11.1523 22.2138C11.1523 23.2255 11.5463 24.1767 12.2618 24.8921C13.0002 25.6305 13.9701 25.9998 14.9401 25.9998C15.9101 25.9998 16.8801 25.6305 17.6184 24.8921V24.8921C17.6185 24.8921 17.6185 24.892 17.6185 24.892L19.7611 22.7494C20.0569 22.4536 20.0569 21.9739 19.761 21.678C19.4653 21.3822 18.9857 21.3822 18.6898 21.678Z"                  
                />
                <path
                  d="M25.155 15.786C25.155 14.7742 24.761 13.823 24.0455 13.1076C22.5687 11.6308 20.1656 11.6308 18.6889 13.1076C18.6888 13.1077 18.6887 13.1078 18.6887 13.1079L16.5461 15.2504C16.2503 15.5462 16.2503 16.0259 16.5461 16.3217C16.6941 16.4697 16.8879 16.5436 17.0818 16.5436C17.2756 16.5436 17.4696 16.4696 17.6174 16.3217L19.76 14.1792C19.7601 14.1791 19.7602 14.179 19.7603 14.1789C20.6463 13.2929 22.0881 13.2928 22.9742 14.1789C23.4034 14.6082 23.6399 15.179 23.6399 15.786C23.6399 16.3929 23.4035 16.9635 22.9744 17.3928C22.9743 17.3929 22.9743 17.3929 22.9742 17.393L20.8316 19.5356C20.5358 19.8314 20.5358 20.3111 20.8316 20.607C20.9796 20.7549 21.1734 20.8289 21.3673 20.8289C21.5612 20.8289 21.755 20.7549 21.903 20.607L24.0456 18.4643C24.0457 18.4642 24.0459 18.464 24.046 18.4638C24.7611 17.7485 25.155 16.7974 25.155 15.786Z"                  
                />
                <path
                  d="M15.4758 21.6781C15.6237 21.826 15.8176 21.9 16.0114 21.9C16.2053 21.9 16.3992 21.826 16.5471 21.6781L20.8325 17.3928C21.1283 17.097 21.1283 16.6173 20.8325 16.3215C20.5367 16.0257 20.057 16.0257 19.7611 16.3215L15.4758 20.6067C15.1799 20.9026 15.1799 21.3823 15.4758 21.6781Z"                  
                />
              </svg>
            </div>
          </div>
          <div className="feedbackCopy" data-cy="feedbackCopy">{copyText}</div>
          <span onClick={() => setShow(false)} data-cy="btnCloseInvite" >
            <svg
              width="13"
              height="13"
              viewBox="0 0 13 13"              
              xmlns="http://www.w3.org/2000/svg"
            >
              <g opacity="0.8">
                <rect
                  x="11.5039"
                  width="2.11702"
                  height="16.0894"
                  rx="1.05851"
                  transform="rotate(45 11.5039 0)"                  
                />
                <rect
                  y="1.49707"
                  width="2.11702"
                  height="16.0894"
                  rx="1.05851"
                  transform="rotate(-45 0 1.49707)"                  
                />
              </g>
            </svg>
          </span>

          <button
           data-cy="btnCopyLinkPass"
            className="copyLinkPass"
            onClick={() => {
              navigator.clipboard.writeText(
                `Link: ${clientLink.replace(
                  "{protocolId}",
                  getProtocolId() ?? ""
                )}
                Senha: ${password}`
              );
              setCopyText("Link e senha copiados com sucesso");
            }}
          >
            Copiar Link e Senha
            <svg
              width="19"
              height="19"
              viewBox="0 0 19 19"              
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.227 13.1311L7.31984 16.0383C7.31984 16.0383 7.31984 16.0384 7.31977 16.0384C7.31977 16.0384 7.31977 16.0384 7.3197 16.0384C6.1175 17.2407 4.16125 17.2408 2.95891 16.0384C2.37645 15.456 2.05575 14.6816 2.05575 13.858C2.05575 13.0344 2.37645 12.2602 2.9587 11.6777C2.95877 11.6776 2.95884 11.6776 2.95891 11.6775L5.86608 8.77026C6.26743 8.36884 6.26743 7.71799 5.86601 7.31664C5.46466 6.91529 4.81381 6.91529 4.41239 7.31664L1.50522 10.2239C1.50501 10.2241 1.50481 10.2244 1.5046 10.2246C0.534358 11.1952 0 12.4855 0 13.858C0 15.2307 0.534563 16.5213 1.50529 17.4921C2.50726 18.494 3.82328 18.9949 5.13938 18.9949C6.45547 18.9949 7.77156 18.494 8.77339 17.4921V17.492C8.77346 17.492 8.77346 17.4919 8.77346 17.4919L11.6806 14.5847C12.082 14.1833 12.082 13.5325 11.6806 13.1311C11.2793 12.7297 10.6285 12.7297 10.227 13.1311Z"                
              />
              <path
                d="M18.9975 5.13692C18.9975 3.76416 18.4629 2.47356 17.4922 1.50283C15.4883 -0.500978 12.2278 -0.500909 10.2241 1.50283C10.224 1.50297 10.2239 1.50304 10.2238 1.50317L7.31669 4.41021C6.91527 4.81156 6.91527 5.46248 7.31669 5.86383C7.51747 6.06461 7.78047 6.16493 8.04353 6.16493C8.30653 6.16493 8.56967 6.06454 8.77031 5.86383L11.6774 2.95679C11.6775 2.95666 11.6776 2.95659 11.6778 2.95645C12.88 1.75425 14.8362 1.75418 16.0386 2.95645C16.6209 3.53891 16.9418 4.31332 16.9418 5.13692C16.9418 5.96045 16.6211 6.73471 16.0388 7.31718C16.0387 7.31725 16.0386 7.31731 16.0386 7.31738L13.1314 10.2246C12.73 10.626 12.73 11.2768 13.1314 11.6782C13.3322 11.879 13.5952 11.9793 13.8582 11.9793C14.1213 11.9793 14.3844 11.879 14.5851 11.6782L17.4922 8.771C17.4924 8.7708 17.4927 8.77052 17.4929 8.77032C18.4631 7.79973 18.9975 6.50934 18.9975 5.13692Z"                
              />
              <path
                d="M5.86747 13.1316C6.06818 13.3323 6.33125 13.4327 6.59425 13.4327C6.85731 13.4327 7.12038 13.3323 7.32109 13.1316L13.1356 7.31713C13.537 6.91578 13.537 6.26493 13.1356 5.86351C12.7342 5.46216 12.0834 5.46216 11.682 5.86351L5.86747 11.6779C5.46605 12.0794 5.46605 12.7303 5.86747 13.1316Z"                
              />
            </svg>
          </button>
        </InviteParticipantBodyPopup>
      </InviteParticipantPopup>
    </InviteParticipantArea>
  );
};
