import { io } from "socket.io-client";
import { getProtocolId } from "../sessionstorage";
import { getIpData, getPayload } from "../utils";

let url = window.location.hostname.indexOf("stage") > -1;
let socketUrl = url
  ? process.env.REACT_APP_SOCKET_URL_STAGE
  : process.env.REACT_APP_SOCKET_URL;

export const socket = io(socketUrl ?? "");

socket.on("disconnect", () => {
  console.log(socket.id);
});

socket.on("connect_error", (err) => {
  console.log(err.message);
  socket.connect();
});

export const connectedInSocket = () => {
  socket.on("connect", () => {
    console.log(socket.id);
    if (getPayload()._id) updateSocketId();
  });
};

export const pauseRoomByAttendant = (roomIsPaused: boolean, cb: Function) => {
  socket.emit(
    "PAUSE_ROOM_BY_ATTENDANT",
    getProtocolId(),
    getPayload().name,
    getPayload().avatar,
    getPayload().roleUser,
    roomIsPaused,
    (res: boolean) => {
      cb(res);
    }
  );
};

export const requestFileByAttendant = (cb: Function) => {
  socket.emit("REQUEST_FILE_BY_ATTEDANT", getProtocolId(), (res: boolean) => {
    cb(res);
  });
};

export const getChatMessages = (cb: Function) => {
  socket.emit("GET_CHAT_MESSAGES", getProtocolId(), (messages: Array<any>) => {
    cb(messages);
  });
};

export const addAttendant = async () => {
  console.log("Add attendant: " + socket.id);
  const payload = getPayload();

  socket.emit("ADD_ATTENDANT", {
    userId: payload._id,
    ip: await getIpData(),
  });
};

export const updateSocketId = () => {
  console.log("UpdatesocketId:" + socket.id);
  const payload = getPayload();
  socket.emit("UPDATE_SOCKET_ID_ATTENDANT", {
    userId: payload._id,
    protocolId: getProtocolId(),
  });
};

export const matchAttendant = (cb: Function) => {
  socket.on("MATCH_ATTENDANT", (protocolId: string, device: string) => {
    cb(protocolId, device);
    updateSocketId();
  });
};

export const attendantGoToQueueAgain = (cb: Function) => {
  socket.on("GO_TO_QUEUE_AGAIN", () => {
    cb();
  });
};

export const attedantCanGoToRoom = (cb: Function) => {
  socket.on("VISITANT_ALREADY_IN_ROOM", () => {
    updateSocketId();
    cb();
  });
};

export const finalizeRoomByVisitant = (cb: Function) => {
  socket.on("FINALIZE_ROOM_TO_ATTENDANT", () => {
    cb();
  });
};

export const chatMessages = (cb: Function) => {
  socket.on("CHAT_MESSAGES", (messages: Array<any>) => {
    cb(messages);
  });
};

export const closeSocketConnection = () => {
  socket.close();
};

export const addMessageIntoChat = (
  person: "visitant" | "attendant",
  protocolId: string,
  msg: string,
  personId: string,
  cb: Function
) => {
  socket.emit(
    "ADD_CHAT_MESSAGE",
    {
      person,
      protocolId,
      msg,
      personId,
    },
    cb
  );
};

export const finalizeRoomByAttendant = (
  data: {
    protocolId: string;
    tabbingFields: any;
    contactAgainMessage?: string;
  },
  cb: Function
) => {
  socket.emit("FINALIZE_ROOM_BY_ATTENDANT", data, cb);
};

export const attendantUpdateStatus = (status: string | null) => {
  const payload = getPayload();
  socket.emit("UPDATE_ATTENDANT_STATUS", {
    userId: payload._id,
    status: status,
  });
};
export const attendantInfo = (cb: Function) => {
  const payload = getPayload();
  socket.emit(
    "GET_ATTENDANT",
    {
      userId: payload._id,
    },
    cb
  );
};

export const attendantLogout = () => {
  const payload = getPayload();
  socket.emit("LOGOUT_ATTENDANT", {
    userId: payload._id,
  });
};

export const verifyPausedRoom = (protocolId: string, cb: Function) => {
  socket.emit("VERIFY_PAUSED_ROOM", {
    protocolId,
  }, cb);
};
