import Select from "react-select";
import styled, { css } from "styled-components";

export const QueueContainer = styled.div`
  width: 100%;
  max-width: 100vw;
  height: 100vh;
  background: linear-gradient(180deg, #2c1050 -18.72%, #0a070f 75.27%);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

export const QueueHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  p {
    color: #fff;
    margin: 20px 0;
  }

  #GameBoard {
    border: 1px solid #fff !important;
    width: 420px !important;
    height: 280px !important;

    #Score {
      color: #fff;
      font-size: 14px !important;
    }
  }

  .lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 58px;
    margin-left: 8px;
  }
  .lds-ellipsis div {
    position: absolute;
    top: 33px;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background: #fff;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  .lds-ellipsis div:nth-child(1) {
    left: 3px;
    animation: lds-ellipsis1 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(2) {
    left: 3px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(3) {
    left: 27px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(4) {
    left: 51px;
    animation: lds-ellipsis3 0.6s infinite;
  }
  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(24px, 0);
    }
  }

  .waiting {
    display: flex;
    align-items: center;
    font-size: 24px;
  }

  @media (max-width: 1024px) {
    img {
      width: 50%;
    }

    .waiting {
      font-size: 12px;
    }

    .lds-ellipsis div {
      width: 3px;
      height: 3px;
    }
  }
`;
export const ExitButton = styled.button`
  width: 142px;
  height: 54px;
  background: #d02a3e;
  box-shadow: 0px 6px 4px rgba(0, 0, 0, 0.25);
  border-radius: 30px;
  margin-top: 320px;
  font-family: "Poppins";
  color: #fff;
  font-weight: bold;
  font-size: 14px;
  letter-spacing: 1px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px 0 40px;
  cursor: pointer;

  &:hover {
    background: #1b0d34;
  }
`;

export const QueueContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  margin-top: 64px;
  img {
    margin-bottom: 32px;
  }
`;

export const ReactSelectBreak = styled(Select)`
  width: 60%;
  & span {
    color: #1f0533;
  }
  & .Select__control {
    width: 100%;
    height: 50px;
    border: 0;
    border-radius: 50px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  & .Select__control_is_focused,
  .Select__menu {
    width: 100%;
    border: 1px solid #1f0533;
    box-shadow: none;
  }
  & .Select__control:hover,
  .Select__menu:hover {
    opacity: 0.8;
    width: 100%;
    border: 1px solid #1f0533;
    box-shadow: none;
  }
  & .Select__placeholder,
  .Select__single-value,
  .Select__input {
    font-family: "Poppins";
    color: #323232;
    font-weight: bold;
    padding-left: 10px;
  }
  & .Select__indicators {
    span {
      background-color: #1f0533;
      width: 0;
    }

    svg {
      width: 30px;
      height: 30px;
    }
  }
  & .Select__indicator:hover {
    color: #1f0533;
  }
  & .Select__indicator {
    color: #fff;
    background: #1f0533;
    border-radius: 50px;
    padding: 8px;
    margin-right: 3px;
  }
`;

export const QueueSideBar = styled.div`
  position: fixed;
  right: 0;
  transition: all 0.5s linear;
  ${(props: { menuIsOpen: boolean }) =>
    !!props.menuIsOpen
      ? css`
          right: 0;
        `
      : css`
          right: -25vw;
        `};
  display: flex;
  flex-direction: column;

  & > div {
    height: 100vh;
    width: 25vw;

    background: #eeeeee;
    box-shadow: -25px 4px 50px 8px rgba(0, 0, 0, 0.25);
    border-radius: 10px 0px 0px 10px;
    position: relative;
    z-index: 1000px;
  }
`;

export const WrapButton = styled.button`
  position: absolute;
  width: 55px;
  height: 164px;
  cursor: pointer;

  background: #eeeeee;
  box-shadow: -21px 8px 30px rgba(0, 0, 0, 0.25);
  border-radius: 10px 0px 0px 10px;

  left: -55px;
  top: 120px;
  &:hover {
    opacity: 0.5;
  }
`;

export const TimeContainer = styled.h2`
  color: #fff;
  font-family: "Arial";
  font-weight: normal;
  font-size: 2rem;
`;

export const StatusText = styled.h1`
  color: #fff;
  font-size: 2rem;
  margin-left: 80px;
`;

export const Logo = styled.img`
  position: absolute;
  bottom: 1.5rem;

  width: clamp(100px, 100vw, 200px);
`;
