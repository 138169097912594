type FormatNumberOptionsAddZero = {
  length: number;
  possition: "start" | "end";
};

export const FormatNumber = {
  addZero(
    value: number | string,
    { length, possition }: FormatNumberOptionsAddZero = {
      length: 2,
      possition: "start",
    }
  ): string {
    const str = value.toString();
    return possition === "start"
      ? str.padStart(length, "0")
      : str.padEnd(length, "0");
  },
};
