import styled, { css, keyframes } from "styled-components";

export const RoomContainer = styled.div<{ bgColor: string; contrastColor: string; }>`
  background: ${({ bgColor, contrastColor }) => `linear-gradient(180deg, ${bgColor}  -18.72%, ${contrastColor} 75.27%)`};
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SideBar = styled.div`
  position: fixed;
  right: 0;
  transition: all 0.5s linear;
  ${(props: { menuIsOpen: boolean }) =>
    !!props.menuIsOpen
      ? css`
          right: -19vw;
        `
      : css`
          right: 0;
        `};
  display: flex;
  flex-direction: column;

  & > div {
    height: 100vh;
    width: 19vw;
    background: #eeeeee;
    box-shadow: -25px 4px 50px 8px rgba(0, 0, 0, 0.25);
    position: relative;
    z-index: 1000px;
  }
`;

export const WrapButton = styled.button`
  position: absolute;
  width: 55px;
  height: 164px;
  cursor: pointer;
  background: #eeeeee;
  border-radius: 10px 0px 0px 10px;

  left: -55px;
  top: 114px;

  @media (min-width: 768px) and (max-width: 1366px) {
    height: 54px;
    left: -54px;
    top: 39px;
  }
  &:hover {
    svg {
      path {
        fill: #5c27b3;
      }
    }
  }

  svg {
    transform: rotate(180deg);
  }
`;

export const SideBarContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const RequiredMessage = styled.span`
  color: red;
  font-size: 12px;
  width: 80%;
  text-align: center;
`;

export const ContactAgainContainer = styled.div`
  position: fixed;
  z-index: 2000;
  width: 500px;
  height: 300px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  border-radius: 15px;
  padding: 20px;
  text-align: center;
  box-shadow: -1px 1px 20px 9999px rgb(0 0 0 / 81%);
  p {
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 22px;
    color: #000000;
    margin-bottom: 18px;
  }
  div {
    display: flex;
    align-items: center;

    button {
      width: 150px;
      height: 50px;
      background-color: #5c27b3;
      border-radius: 50px;
      padding: 10px 30px;
      color: #fff;
      margin-left: 8px;
      cursor: pointer;

      &:hover {
        filter: brightness(1.2);
      }
    }
    button:first-child {
      margin-left: 0px;
    }
  }
`;

export const HeaderLogo = styled.div`
  ${(props: { backgroundLogoColor: string }) =>
    props.backgroundLogoColor
      ? `
          background-color: ${props.backgroundLogoColor};
        `
      : css`
          display: none;
        `}

  display:flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  img {
    margin: 16px 0;
    filter: brightness(0) invert(1);
  }
`;

export const TabbingInputContainer = styled.div`
  margin-top: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;

  #select-name h3 {
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 22px;
    color: #000000;
    margin-bottom: 18px;
  }

  .tabbing-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;

    & > div {

      border-radius: 20px;
      margin-top: 12px;
      width: 80%;
      height: fit-content;

      span {
        background: #ffffff;
        opacity: 0.75;

        border-radius: 20px 0px 0px 20px;
        width: fit-content;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-left: 8px;

        p {
          font-family: Poppins;
          font-style: normal;
          font-weight: bold;
          font-size: 13px;
          line-height: 19px;
          color: #000000;
        }
      }

      p {
        font-family: Poppins;
        font-style: normal;
        font-weight: bold;
        font-size: 13px;
        margin-bottom: 5px;
        line-height: 19px;
        color: #000000;
      }
      
      textarea {
        margin: 0;
        padding-top: 12px;
        padding-left: 4px;
        background: #ffffff;
        opacity: 0.75;

        border-radius: 0px 20px 20px 20px;
        width: 100%;
        min-height: 40px;
      }
      input {
        margin: 0;
        padding-left: 4px;
        background: #ffffff;

        border-radius: 0px 20px 20px 0px;
        width: 100%;
        height: 40px;
        border-radius: 4px;
      }
    }
  }

  hr {
    margin-top: 32px;
    border: 1px solid #d2d2d2;
    width: 80%;
  }
`;

export const FinalizeButton = styled.button`
  background: #5c27b3;
  box-shadow: 0px 6px 4px rgba(0, 0, 0, 0.25);
  border-radius: 30px;
  width: 142px;
  height: 53px;
  color: #fff;
  cursor: pointer;
  position: absolute;
  bottom: 25px;
  font-size: 14px;
  font-weight: bold;
  font-family: "Poppins";
  padding-right: 30px;

  &:hover {
    opacity: 0.8;
  }

  svg {
    position: absolute;
    right: 17px;
  }

  #spinner {
    min-width: 15px;
    min-height: 15px;
    border: 3px solid #810ad1;
    border-right: 3px solid orange;
    border-radius: 50%;
    animation: spinner 1s linear infinite;
    max-width: 15px;
    position: absolute;
    right: 19px;
    top: 17px;
    padding: 0;
  }

  @keyframes spinner {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;

export const ButtonsContainerBottom = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: row;
  flex-wrap: nowrap;
  margin: 0 auto;

  div {
    width: 48px;
    height: 48px;
    margin: 0;
    padding: 0;
    min-width: auto;

    span {
      display: none;
    }
  }
  > div,
  button {
    + div {
      margin-left: 16px;
    }
    + button {
      margin-left: 16px;
    }
  }

  .btn-end-ofensive {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;
    padding: unset;
    border-radius: 50px;
    cursor: pointer;

    img {
      width: 22px;
    }
  }

  @media (max-width: 600px) {
    display: block;
  }
`;

export const SwitchButtonContainer = styled.div`
  display: flex;
  padding: 8px 0 0;

  button {
    margin-right: 20px;
  }
`;

export const VideoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 74vw;
  height: 100vh;
  transition: all 0.5s linear;
  ${(props: { menuIsOpen: boolean }) =>
    !props.menuIsOpen &&
    css`
      margin-right: 17vw;
    `}

  .container-video {
    justify-content: center;
    display: flex;
    width: 100%;
    align-items: center;
  }
`;

export const NameContainer = styled.div<{  textColor: string }>`
  text-align: center;
  color: ${props => props.textColor};
  margin-bottom: 8px;
`;

export const AttendantPaused = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 90%;
  z-index: 800;
  top: 0px;
  left: 0px;
  border-radius: 10px;
  background: #000;

  .lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 58px;
    margin-left: 8px;
  }

  .lds-ellipsis div {
    position: absolute;
    top: 33px;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background: #fff;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  .lds-ellipsis div:nth-child(1) {
    left: 3px;
    animation: lds-ellipsis1 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(2) {
    left: 3px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(3) {
    left: 27px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(4) {
    left: 51px;
    animation: lds-ellipsis3 0.6s infinite;
  }
  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(24px, 0);
    }
  }

  .waiting {
    display: flex;
    align-items: center;
    font-size: 24px;
  }

  @media (max-width: 1024px) {
    img {
      width: 50%;
    }

    .waiting {
      font-size: 12px;
    }

    .lds-ellipsis div {
      width: 3px;
      height: 3px;
    }
  }
`;

export const VideoContent = styled.div`
  position: relative;

  div[id*="-visitant"] {
    width: 100%;
    height: 100%;
    display: flex;
    align-content: center;
    justify-content: center;
  }

  #participants {
    width: 100%;
    height: auto;
    border-radius: 10px;
    margin-bottom: 15px;
    overflow: hidden;

    video {
      width: 100%;
      height: auto;
      border-radius: 10px;
      transform: scaleX(-1);
    }

    .icon-avatar {
      width: 100%;
      height: 100%;
      min-height: 40vh;
      background-color: #f0f0f0;
      z-index: 1;
      border-radius: 22px;
      top: 0;

      div {
        margin: -18px auto !important;
      }
    }

    h3 {
      text-align: center;
      color: #000;
      z-index: 10;
      position: relative;
      padding-left: 100px;
      padding-top: 65px;
      font-size: 24px;
    }
  }
  #local-video {
    position: absolute;
    z-index: 500;
    top: 15px;
    left: 16px;
    border-radius: 10px;
    max-width: 220px;
    @media (min-width: 768px) and (max-width: 1366px) {
    }
    video {
      border-radius: 10px;
      width: 100%;
      transform: scaleX(-1);
    }

    &.chatOpenLocal {
      top: 15px;
    }
  }
`;

export const InviteParticipantButton = styled.button`
  width: 48px;
  height: 48px;
  padding: 10px;
  text-align: center;
  border-radius: 60px;
  background-color: #fff;
  color: #000;
  font-weight: bold;
  font-family: "Poppins";
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-evenly;

  svg {
    width: 24px;
    margin-top: 2px;
  }
`;

export const AreaTopButtons = styled.div`
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end;
  width: 100%;
`;

export const LocalVideoArea = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  max-width: 20%;
  max-height: 20%;
  border-radius: 8px;
  z-index: 200;
  top: 15px;
  left: 16px;

  video {
    transform: scaleX(-1);
    width: 100%;
    border-radius: 10px;
  }

  .icon-avatar {
    width: 100%;
    height: fit-content;
    background-color: #ccc;
    z-index: 1500;
    border-radius: 10px;
    top: 0;
    position: absolute;
  }
`;

export const GuestVideoArea = styled.div`
  position: absolute;
  border-radius: 8px;
  z-index: 200;
  top: 15px;
  right: 16px;
  width: 100%;
  height: 100%;
  max-width: 20%;
  max-height: 20%;

  video {
    transform: scaleX(-1);
    width: 100%;
    border-radius: 10px;

    :disabled {
      background-color: #fff;
    }
  }

  .icon-avatar {
    width: 100%;
    height: fit-content;
    background-color: #ccc;
    z-index: 1500;
    border-radius: 10px;
    top: 0;
    position: absolute;
  }
`;

export const VisitantVideoArea = styled.div`
  width: fit-content;
  border-radius: 10px;
  margin-bottom: 15px;
  overflow: hidden;
  height: 80%;
  min-width: 600px;
  min-height: 400px;

  display: ${({ useMobile }: { useMobile: boolean }) =>
    useMobile ? "flex" : "flex"};
  justify-content: ${({ useMobile }: { useMobile: boolean }) =>
    useMobile ? "center" : "center"};

  video {
    width: 100vw;
    height: auto;
    max-width: 948px;
    max-height: 800px;
    transform: scaleX(-1);
    border-radius: 15px;
    max-height: 600px;
    object-fit: cover;
  }

  div {

    .icon-avatar {
      width: 100%;
      height: 100%;
      min-height: 40vh;
      background-color: #f0f0f0;
      z-index: 300;
      border-radius: 22px;
      top: 0;

      h5 {
        text-align: center;
        font-size: 24px;
        margin-top: 70px;
        position: absolute;
      }
    }
  }
`;

export const VideoDisabledIconContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 8px;

  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  text-align: center;

  min-width: ${({ props }: { props: any }) => props.width + "px"};
  min-height: ${({ props }: { props: any }) => props.height + "px"};
  img {
    width: auto;
  }
`;

export const TimerView = styled.div`
  display: flex;
  flex: 2;
  padding: 0.1rem 0.8rem !important;
  background: white;
  justify-content: space-between;
  align-items: center;
  border-radius: 1000px;
  max-width: 100px;
  color: #5c27b3;
  font-family: 'Arial';
  font-size: 17px;
  font-weight: bold;
`;

const animationTimer = keyframes`
  0% {
    background-color: #fff;
  } 100%{

    background-color: #5c27b3;

  }`;
export const TimerIcon = styled.div<{ txtColor: string; bgColor: string; }>`
  position: relative;
  overflow: hidden;
  border-radius: 1000px;
  max-width: 1.5rem;
  max-height: 1.5rem;
  background-color: ${({ bgColor }) => bgColor || "#fff"};
  border: 0.1rem solid ${({ txtColor }) => txtColor || "#5c27b3"};

  &:after {
    content: " ";
    position: absolute;
    border-radius: 1000px;
    top: 50%;
    right: 50%;
    width: 25%;
    height: 25%;
    background-color: ${({ bgColor }) => bgColor || "#fff"};
    border: 0.1rem solid ${({ txtColor }) => txtColor || "#5c27b3"};
    transform: translateX(50%) translateY(-50%);
    animation: ${animationTimer} 1s alternate infinite ease-in-out;
  }
`;

export const TabulationHeading = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;

  .lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 58px;
    margin-left: 8px;
  }
  .lds-ellipsis div {
    position: absolute;
    top: 33px;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background: #fff;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  .lds-ellipsis div:nth-child(1) {
    left: 3px;
    animation: lds-ellipsis1 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(2) {
    left: 3px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(3) {
    left: 27px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(4) {
    left: 51px;
    animation: lds-ellipsis3 0.6s infinite;
  }
  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(24px, 0);
    }
  }
  .waiting {
    display: flex;
    align-items: center;
    font-size: 24px;
  }

  @media (max-width: 1024px) {
    img {
      width: 50%;
    }

    .waiting {
      font-size: 12px;
    }

    .lds-ellipsis div {
      width: 3px;
      height: 3px;
    }
  }
`;
export const StatusText = styled.h1`
  color: #fff;
  font-size: 2rem;
  margin-left: 80px;
`;

export const TimeContainer = styled.h2`
  color: #fff;
  font-family: "Arial";
  font-weight: normal;
  font-size: 2rem;
`;
export const Logo = styled.img`
  position: absolute;
  bottom: 1.5rem;

  width: clamp(100px, 100vw, 200px);
`;
