import { useState, useEffect, useRef } from "react";
import {
  Participant as ParticipantTwilio,
  VideoTrack,
  AudioTrack,
} from "twilio-video";
import { BackgroundWhenTrackIsZero } from "./styles";

export const Participant = ({
  participant,
  enableDisableVideoUpdate,
  clientLogo,
  isGuest,
  isVisitant,
  finalizeCall,
  areaRef,
  finalizeByVisitant,
  showLogo = true,
}: {
  participant: ParticipantTwilio;
  enableDisableVideoUpdate?: number;
  clientLogo?: string;
  isGuest?: Boolean;
  finalizeCall?: Boolean;
  finalizeByVisitant?: Boolean;
  areaRef?: any;
  isVisitant?: Boolean;
  showLogo?: Boolean;
}) => {
  const [videoTracks, setVideoTracks] = useState<Array<VideoTrack>>([]);
  const [audioTracks, setAudioTracks] = useState<Array<AudioTrack>>([]);
  const [videoEnabled, setVideoEnabled] = useState<boolean>(false);

  const videoRef = useRef<any>();
  const audioRef = useRef<any>();

  useEffect(() => {}, [enableDisableVideoUpdate]);

  const trackpubsToTracks = (trackMap: any) => {
    return Array.from(trackMap.values())
      .map((publication: any) => publication.track)
      .filter((track) => track !== null);
  };

  const trackSubscribed = (track: any) => {
    if (track.kind === "video") {
      setVideoTracks((videoTracks) => [...videoTracks, track]);
      setVideoEnabled(true);
    } else if (track.kind === "audio") {
      setAudioTracks((audioTracks) => [...audioTracks, track]);
    }
  };

  const trackUnsubscribed = (track: any) => {
    if (track.kind === "video") {
      setVideoTracks([]);
      setVideoEnabled(false);
    } else if (track.kind === "audio") {
      setAudioTracks((audioTracks) => audioTracks.filter((a) => a !== track));
    }
  };

  useEffect(() => {
    if (!participant) return;

    setVideoTracks(trackpubsToTracks(participant.videoTracks));
    setAudioTracks(trackpubsToTracks(participant.audioTracks));

    participant.on("trackSubscribed", trackSubscribed);
    participant.on("trackUnsubscribed", trackUnsubscribed);
    participant.videoTracks.clear();

    return () => {
      setVideoTracks([]);
      setAudioTracks([]);
      participant.removeAllListeners();
    };
  }, [participant, enableDisableVideoUpdate]);

  useEffect(() => {
    const videoTrack = videoTracks[videoTracks.length - 1];

    if (videoTrack) {
      videoTrack.attach(videoRef.current);

      videoTrack.on("enabled", () => setVideoEnabled(true));
      videoTrack.on("disabled", () => setVideoEnabled(false));
      setVideoEnabled(videoTrack.isEnabled);
      return () => {
        videoTrack.detach();
      };
    }
  }, [videoTracks]);

  useEffect(() => {
    const audioTrack = audioTracks[audioTracks.length - 1];
    if (audioTrack) {
      audioTrack.attach(audioRef.current);
      return () => {
        audioTrack.detach();
      };
    }
  }, [audioTracks]);

  return (
    <>
      {!videoEnabled || finalizeByVisitant ? (
        <BackgroundWhenTrackIsZero
        props={{
          width: areaRef?.current?.clientWidth,
          height: areaRef?.current?.clientHeight,
          img: isVisitant,
          sizeImg: isGuest ? 3 : 5,
        }}
        >
          {showLogo && (
            <>
            {!finalizeCall || finalizeByVisitant ? (
              <img src={clientLogo} alt="logo do cliente" />
            ) : null}
          </>
          )}

          {finalizeByVisitant ? (
            <h1>Cliente saiu da chamada</h1>
          ) : !finalizeCall && !isGuest ? (
            <h1>Vídeo desabilitado</h1>
          ) : null}
        </BackgroundWhenTrackIsZero>
      ) : (
        <></>
      )}
      {!finalizeByVisitant ? (
        <video
          ref={videoRef}
          autoPlay={true}
          style={{ display: videoEnabled ? "block" : "none" }}
        />
      ) : null}

      <audio ref={audioRef} autoPlay={true} />
    </>
  );
};
