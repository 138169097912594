import React from "react";
import { Switch } from "react-router-dom";
import { Login } from "../pages/login/index";
import { Queue } from "../pages/queue/index";
import { Room } from "../pages/room/index";
import Route from "./Route";

const Routes: React.FC = () => (
  <Switch>
    <Route path="/login" exact component={Login} />
    <Route path="/" isPrivate={true} exact component={Queue} />
    <Route path="/sala/:protocolId" isPrivate={true} exact component={Room} />
  </Switch>
);

export default Routes;
