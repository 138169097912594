import styled from "styled-components";
import iconArrow from "../../assets/images/arrow-r.svg";
import iconFilePurple from "../../assets/images/file-purple.svg";
import iconFile from "../../assets/images/file.svg";
import iconSend from "../../assets/images/send.svg";

export const FileRequestButton = styled.div<{ bgColor: string }>`
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 20px;
  position: absolute;
  right: 40px;
  top: 14px;

  button {
    box-shadow: 2px 0px 10px rgba(0, 0, 0, 0.25);
    background: #ffffff;
    width: 39px;
    height: 39px;
    border-radius: 100%;
    right: 63px;
    top: 13px;
    border: 0;
    cursor: pointer;
    -webkit-transition: 0.2s all;
    -webkit-transition: 0.2s all;
    
    &:hover {
      opacity: 0.9;
      background-size: 10px;
      color: #fff;
    }
  }
`;
export const ChatStyled = styled.div<{ roomAssets: any; }>`
  background: #1a1422;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  height: 100%;
  max-width: 360px;
  margin-left: 42px;

  &.chatOpen {
    min-width: 360px;
    height: 61vh;
    margin-top: -86px;

    @media (max-width: 1366px) and (min-width: 768px) {
      min-width: 285px;
    }
  }

  .window {
    position: relative;
    margin: auto;
    display: flex;
    flex-direction: column;
    max-height: 680px;
    height: 100%;
  }

  .chat {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0.675rem;
    gap: 0.5rem;
    height: 100%;
    overflow-y: scroll;
    scrollbar-width: auto;
    scrollbar-color: #8f54a0 #1a1422;
    ::-webkit-scrollbar {
      width: 16px;
    }
    ::-webkit-scrollbar-track {
      background: #1a1422;
    }
    ::-webkit-scrollbar-thumb {
      background-color: #8f54a0;
      border-radius: 10px;
      border: 3px solid #1a1422;
    }
  }

  .chat_message {
    color: white;
    max-width: 200px;
    line-height: 1.25;
  }

  .chat_message:not([data-user="me"]) + .chat_message[data-user="me"] {
    margin-top: 0.5rem;
  }

  .chat_message[data-user="me"] + .chat_message:not([data-user="me"]) {
    margin-top: 0.5rem;
  }

  .chat_content {
    padding: 0.5rem 1.2rem 0.5rem 0.7rem;
    border-radius: 1px 20px 20px 14px;
    -webkit-mask-image: -webkit-radial-gradient(white, black);
    overflow: hidden;
    position: relative;
    background: #1a1422;
    min-width: fit-content;
  }

  .chat_message .chat_content::before {
    content: "";
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    transform: translate3d(0, var(--scrollTop), 0);
  }

  .chat_message[data-user="me"] .chat_content::before {
    color: #000;
  }

  .chat_message .chat_content::before {
    color: #000;
  }

  .chat_content span {
    width: fit-content;
    z-index: 1;
    position: relative;
    font-size: 13px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    word-break: break-word;
    & > img {
      margin-right: 10px;
    }
  }

  .chat_message[data-user="me"] {
    align-self: flex-end;
  }

  .chat_message[data-user="me"] .chat_content {
    border-bottom-right-radius: 0;
    color: #000;
    border-top-left-radius: 26px;
    border-bottom-left-radius: 26px;
    border-top-right-radius: 25px;
  }

  .new-message {
    display: flex;
    align-items: center;
    gap: 0.675rem;
    padding: 0.675rem;
    position: relative;
    box-sizing: border-box;
    width: 100%;
  }

  .new-message_input {
    border: none;
    border-radius: 3rem;
    padding: 1rem 1rem;
    width: 100%;
    resize: none;
    padding-right: 55px;
    max-height: 14px;
    box-sizing: unset;
    overflow: hidden;
  }

  .new-message_input:focus {
    outline: none;
    border: 0;
  }

  .new-message_submit {
    background: ${({roomAssets}) => roomAssets?.buttonColor || "#6229bf"};
    width: 39px;
    height: 39px;
    border-radius: 100%;
    position: absolute;
    right: 14px;
    border: 0;
    cursor: pointer;

    &:hover {
      opacity: 0.9;
    }
  }

  .new-message_submit:active {
    color: white;
  }

  .chat-loading {
    min-width: 15px;
    min-height: 15px;
    border: 3px solid #810ad1;
    border-right: 3px solid orange;
    border-radius: 50%;
    animation: spinner 1s linear infinite;
    max-width: 15px;
    position: absolute;
    right: 26px;
    top: 25px;
  }

  @keyframes spinner {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  .closeChat {
    position: absolute;
    background: #1a1422 url(${iconArrow}) no-repeat center center;
    left: -18px;
    top: 30px;
    width: 18px;
    height: 74px;
    border-radius: 7.2px 0 0px 7px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    cursor: pointer;
  }

  .input-file {
    margin: 10px auto;
    max-width: 70%;
    width: 100%;
    position: relative;
    margin-bottom: 0;
    cursor: pointer;

    @media (max-width: 1366px) and (min-width: 768px) {
      max-width: 95%;
    }

    &:after {
      content: "";
      position: absolute;
      width: 40px;
      height: 40px;
      background: #6229bf url(${iconFile}) no-repeat center;
      border-radius: 60px;
      top: 3px;
      left: 3px;
    }

    .chat_content {
      border-radius: 26px !important;
      height: 29px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .input-file input {
    display: none;
  }
  
`;


export const FileuploadArea = styled.label`
  cursor: pointer;
  width: 80%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-items: center;
  margin: 0 auto;
  background-color: #ffffff;
  padding: 5px;
  border-radius: 30px;
  margin-top: 10px;
  margin-bottom: 5px;
`;

export const FileIconArea = styled.div`
  height: 20px;
  width: 20px;
  padding: 5px ;
  background-color: #6229bf;
  border-radius: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  align-items: center;
`;
