import styled, { css } from "styled-components";
import iconUser from "../../assets/images/user-video.svg";

export const DeviceSelectionContainer = styled.div`
  margin-top: 24px;
`;

export const LogoContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

export const LogoArea = styled.div`
  opacity: 0.9;
  border-radius: 12px;
  width: fit-content;
  margin-bottom: 20px;
  max-width: 50%;
  max-height: 20%;
  display: flex;

  img {
    max-width: 100px;
  }
`;
export const DeviceSelectionContent = styled.div`
  background: #000000;

  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  border-radius: 22px;
  width: auto;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px 0px;

  ${(props: { isMobile: boolean }) =>
    props.isMobile
      ? css`
          height: 70vh;
          min-width: 350px;
        `
      : css`
          height: 50vh;
        `}

  @media (max-width: 768px) {
    padding: 0px;
    margin: 15px;
  }
`;

export const LocalVideo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  video {
    border-radius: 20px;
    ${(props: { isMobile: boolean }) =>
      props.isMobile
        ? css`
            width: "auto";
            height: 400px;
          `
        : css`
            width: 80%;
          `}
  }

  @media (max-width: 768px) {
    video {
      width: 80%;
    }
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 22px;
  box-sizing: border-box;
  padding: 15px;
`;
export const EnterInRoomButton = styled.button`
  width: 168px;
  height: 53px;
  background: #ffffff;
  box-shadow: 0px 6px 4px rgba(0, 0, 0, 0.25);
  border-radius: 50px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  position: relative;
  font-family: "Poppins";
  padding-right: 13px;

  &:hover {
    background-color: #6709a7;
    color: #fff;

    svg {
      path {
        fill: #fff;
      }
    }
  }

  svg {
    position: absolute;
    right: 23px;
    top: 19px;
  }
`;
export const LocalVideoLoading = styled.div`
  width: 506px;
  height: 306px;

  background: #b2b2b4 url(${iconUser}) no-repeat center center;
  display: flex;
  border-radius: 10px;
`;
export const MatchScreenContent = styled.div`
  ${(props: { backgroundColor: string }) =>
    props.backgroundColor
      ? `
          background: linear-gradient(
            180deg,
            ${props.backgroundColor} -7.27%,
            #614be4 95.11%
          );
        `
      : css`
          background: linear-gradient(180deg, #00ffe6 -7.27%, #614be4 95.11%);
        `}

  z-index:800;
  width: 100vw;
  height: 100vh;
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  & > img {
    margin-bottom: 32px;
  }

  #spinner {
    min-width: 15px;
    min-height: 15px;
    border: 3px solid #810ad1;
    border-right: 3px solid orange;
    border-radius: 50%;
    animation: spinner 1s linear infinite;
    max-width: 15px;
    position: absolute;
    right: 19px;
    top: 17px;
    padding: 0;
  }

  @keyframes spinner {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;
