import CamOffIcon from "./images/camOff.png";
import CamOnIcon from "./images/camOn.png";
import MicOnIcon from "./images/micOn.png";
import MicOffIcon from "./images/micOff.png";

export const iconRating = {
  starEmpty: (
    <svg
      width="57"
      height="56"
      viewBox="0 0 57 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="28.5391" cy="28" r="28" fill="white" fillOpacity="0.1" />
      <path
        d="M43.0473 23.9823L43.0474 23.9824C43.0923 24.1206 43.0548 24.2723 42.9507 24.3738L42.9507 24.3738L36.197 30.9566L36.0088 31.1401L36.0532 31.3992L37.6476 40.6941C37.6476 40.6942 37.6477 40.6942 37.6477 40.6942C37.6722 40.8374 37.6133 40.9823 37.4956 41.0678C37.378 41.1532 37.2222 41.1646 37.0933 41.0969C37.0933 41.0969 37.0933 41.0969 37.0933 41.0968L28.7462 36.7083L28.5135 36.586L28.2808 36.7083L19.9333 41.0969L19.9332 41.097C19.8774 41.1263 19.8166 41.1408 19.7557 41.1408C19.6763 41.1408 19.5976 41.1162 19.5311 41.0679C19.4134 40.9823 19.3546 40.8375 19.3792 40.6943L19.3792 40.6943L20.9731 31.3992L21.0175 31.1401L20.8293 30.9566L14.0764 24.3739L14.0763 24.3738C13.9722 24.2724 13.9347 24.1206 13.9797 23.9823L13.9797 23.9822C14.0246 23.844 14.1442 23.7432 14.2879 23.7223L14.288 23.7223L23.6205 22.3661L23.8807 22.3283L23.997 22.0926L28.171 13.6358L27.7226 13.4145L28.171 13.6358C28.2354 13.5054 28.3681 13.4229 28.5136 13.4229C28.659 13.4229 28.7917 13.5054 28.856 13.6357L28.856 13.6358L33.0299 22.0926L33.1462 22.3283L33.4063 22.3661L42.7391 23.7223L42.7392 23.7223C42.8829 23.7432 43.0024 23.8439 43.0473 23.9823Z"
        stroke="white"
        stroke-opacity="0.3"
      />
    </svg>
  ),
  starFull: (
    <svg
      width="57"
      height="56"
      viewBox="0 0 57 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="28.3594" cy="28" r="28" fill="#6229BF" />
      <path
        d="M42.8676 23.9823L42.8677 23.9824C42.9126 24.1206 42.8751 24.2723 42.771 24.3738L42.771 24.3738L36.0173 30.9566L35.8291 31.1401L35.8735 31.3992L37.468 40.6941C37.468 40.6942 37.468 40.6942 37.468 40.6942C37.4925 40.8374 37.4336 40.9823 37.3159 41.0678C37.1984 41.1532 37.0425 41.1646 36.9136 41.0969C36.9136 41.0969 36.9136 41.0969 36.9136 41.0968L28.5665 36.7083L28.3338 36.586L28.1011 36.7083L19.7536 41.0969L19.7536 41.097C19.6977 41.1263 19.6369 41.1408 19.576 41.1408C19.4966 41.1408 19.4179 41.1162 19.3514 41.0679C19.2337 40.9823 19.1749 40.8375 19.1995 40.6943L19.1995 40.6943L20.7934 31.3992L20.8378 31.1401L20.6496 30.9566L13.8967 24.3739L13.8966 24.3738C13.7925 24.2724 13.755 24.1206 13.8 23.9823L13.8 23.9822C13.8449 23.844 13.9645 23.7432 14.1082 23.7223L14.1083 23.7223L23.4409 22.3661L23.701 22.3283L23.8173 22.0926L27.9913 13.6358L27.543 13.4145L27.9913 13.6358C28.0557 13.5054 28.1884 13.4229 28.3339 13.4229C28.4793 13.4229 28.612 13.5054 28.6763 13.6357L28.6763 13.6358L32.8502 22.0926L32.9665 22.3283L33.2266 22.3661L42.5594 23.7223L42.5595 23.7223C42.7032 23.7432 42.8227 23.8439 42.8676 23.9823Z"
        fill="white"
        stroke="white"
      />
    </svg>
  ),
  smilesEmpty: [
    <svg
      width="56"
      height="56"
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="28" cy="28" r="28" fill="white" fillOpacity="0.1" />
      <path
        d="M28.1027 14.8467C20.519 14.8467 14.3711 20.9945 14.3711 28.5783C14.3711 36.1621 20.519 42.31 28.1027 42.31C35.6865 42.31 41.8344 36.1621 41.8344 28.5783C41.8263 20.9979 35.6832 14.8548 28.1027 14.8467ZM28.1027 40.3483C21.6023 40.3483 16.3327 35.0787 16.3327 28.5783C16.3327 22.0779 21.6023 16.8083 28.1027 16.8083C34.6031 16.8083 39.8727 22.0779 39.8727 28.5783C39.8657 35.0758 34.6003 40.3413 28.1027 40.3483Z"
        fill="#FAFAFA"
        fillOpacity="0.3"
      />
      <path
        d="M23.1999 28.5786C24.2833 28.5786 25.1616 27.7003 25.1616 26.6169C25.1616 25.5335 24.2833 24.6553 23.1999 24.6553C22.1165 24.6553 21.2383 25.5335 21.2383 26.6169C21.2383 27.7003 22.1165 28.5786 23.1999 28.5786Z"
        fill="white"
        fillOpacity="0.3"
      />
      <path
        d="M33.0085 28.5786C34.0919 28.5786 34.9702 27.7003 34.9702 26.6169C34.9702 25.5335 34.0919 24.6553 33.0085 24.6553C31.9251 24.6553 31.0469 25.5335 31.0469 26.6169C31.0469 27.7003 31.9251 28.5786 33.0085 28.5786Z"
        fill="white"
        fillOpacity="0.3"
      />
      <path
        d="M24.3519 34.075C24.6759 34.5091 25.2904 34.5984 25.7246 34.2744C25.7434 34.2604 25.7616 34.2457 25.7793 34.2304C27.1664 33.2173 29.0493 33.2173 30.4364 34.2304C30.8462 34.5846 31.4656 34.5395 31.8198 34.1297C32.1741 33.7199 32.1289 33.1004 31.7191 32.7462C31.7014 32.7309 31.6831 32.7162 31.6643 32.7022C29.5649 31.0975 26.6508 31.0975 24.5514 32.7022C24.1172 33.0262 24.0279 33.6408 24.3519 34.075Z"
        fill="white"
        fillOpacity="0.3"
      />
      <rect
        x="22.125"
        y="20.6514"
        width="5.38653"
        height="1.66665"
        rx="0.833325"
        transform="rotate(33.2392 22.125 20.6514)"
        fill="white"
        fillOpacity="0.3"
      />
      <rect
        width="5.38653"
        height="1.66665"
        rx="0.833325"
        transform="matrix(-0.83639 0.548135 0.548135 0.83639 33.5312 20.6514)"
        fill="white"
        fillOpacity="0.3"
      />
    </svg>,
    <svg
      width="56"
      height="56"
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="28" cy="28" r="28" fill="white" fillOpacity="0.1" />
      <path
        d="M28.341 14.8467C20.7572 14.8467 14.6094 20.9945 14.6094 28.5783C14.6094 36.1621 20.7572 42.31 28.341 42.31C35.9248 42.31 42.0727 36.1621 42.0727 28.5783C42.0646 20.9979 35.9215 14.8548 28.341 14.8467ZM28.341 40.3483C21.8406 40.3483 16.571 35.0787 16.571 28.5783C16.571 22.0779 21.8406 16.8083 28.341 16.8083C34.8414 16.8083 40.111 22.0779 40.111 28.5783C40.104 35.0758 34.8385 40.3413 28.341 40.3483Z"
        fill="white"
        fillOpacity="0.3"
      />
      <path
        d="M23.4343 28.5781C24.5177 28.5781 25.396 27.6998 25.396 26.6164C25.396 25.533 24.5177 24.6548 23.4343 24.6548C22.3509 24.6548 21.4727 25.533 21.4727 26.6164C21.4727 27.6998 22.3509 28.5781 23.4343 28.5781Z"
        fill="white"
        fillOpacity="0.3"
      />
      <path
        d="M33.2468 28.5781C34.3302 28.5781 35.2085 27.6998 35.2085 26.6164C35.2085 25.533 34.3302 24.6548 33.2468 24.6548C32.1634 24.6548 31.2852 25.533 31.2852 26.6164C31.2852 27.6998 32.1634 28.5781 33.2468 28.5781Z"
        fill="white"
        fillOpacity="0.3"
      />
      <path
        d="M24.5863 34.075C24.9102 34.5091 25.5248 34.5984 25.959 34.2744C25.9777 34.2604 25.996 34.2457 26.0137 34.2304C27.4008 33.2173 29.2837 33.2173 30.6707 34.2304C31.0806 34.5846 31.7 34.5395 32.0542 34.1297C32.4084 33.7199 32.3633 33.1004 31.9535 32.7462C31.9358 32.7309 31.9175 32.7162 31.8987 32.7022C29.7993 31.0975 26.8852 31.0975 24.7857 32.7022C24.3516 33.0262 24.2622 33.6408 24.5863 34.075Z"
        fill="white"
        fillOpacity="0.3"
      />
    </svg>,
    <svg
      width="56"
      height="56"
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="28" cy="28" r="28" fill="white" fillOpacity="0.1" />
      <path
        d="M28.5754 14.8467C20.9916 14.8467 14.8438 20.9945 14.8438 28.5783C14.8438 36.1621 20.9916 42.31 28.5754 42.31C36.1592 42.31 42.307 36.1621 42.307 28.5783C42.299 20.9979 36.1558 14.8548 28.5754 14.8467ZM28.5754 40.3483C22.075 40.3483 16.8054 35.0787 16.8054 28.5783C16.8054 22.0779 22.075 16.8083 28.5754 16.8083C35.0758 16.8083 40.3454 22.0779 40.3454 28.5783C40.3384 35.0758 35.0729 40.3413 28.5754 40.3483Z"
        fill="white"
        fillOpacity="0.3"
      />
      <path
        d="M23.6765 28.5786C24.7599 28.5786 25.6381 27.7003 25.6381 26.6169C25.6381 25.5335 24.7599 24.6553 23.6765 24.6553C22.5931 24.6553 21.7148 25.5335 21.7148 26.6169C21.7148 27.7003 22.5931 28.5786 23.6765 28.5786Z"
        fill="white"
        fillOpacity="0.3"
      />
      <path
        d="M33.4812 28.5786C34.5646 28.5786 35.4428 27.7003 35.4428 26.6169C35.4428 25.5335 34.5646 24.6553 33.4812 24.6553C32.3978 24.6553 31.5195 25.5335 31.5195 26.6169C31.5195 27.7003 32.3978 28.5786 33.4812 28.5786Z"
        fill="white"
        fillOpacity="0.3"
      />
      <rect
        x="23.6953"
        y="31.8008"
        width="9.77002"
        height="2.18389"
        rx="1.09194"
        fill="white"
        fillOpacity="0.3"
      />
    </svg>,
    <svg
      width="56"
      height="56"
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="28" cy="28" r="28" fill="white" fillOpacity="0.1" />
      <path
        d="M28.1027 14.8467C20.519 14.8467 14.3711 20.9945 14.3711 28.5783C14.3711 36.1621 20.519 42.31 28.1027 42.31C35.6865 42.31 41.8344 36.1621 41.8344 28.5783C41.8263 20.9979 35.6832 14.8548 28.1027 14.8467ZM28.1027 40.3483C21.6023 40.3483 16.3327 35.0787 16.3327 28.5783C16.3327 22.0779 21.6023 16.8083 28.1027 16.8083C34.6031 16.8083 39.8727 22.0779 39.8727 28.5783C39.8657 35.0758 34.6003 40.3413 28.1027 40.3483Z"
        fill="#FAFAFA"
        fillOpacity="0.3"
      />
      <path
        d="M23.1999 28.5786C24.2833 28.5786 25.1616 27.7003 25.1616 26.6169C25.1616 25.5335 24.2833 24.6553 23.1999 24.6553C22.1165 24.6553 21.2383 25.5335 21.2383 26.6169C21.2383 27.7003 22.1165 28.5786 23.1999 28.5786Z"
        fill="white"
        fillOpacity="0.3"
      />
      <path
        d="M33.0085 28.5786C34.0919 28.5786 34.9702 27.7003 34.9702 26.6169C34.9702 25.5335 34.0919 24.6553 33.0085 24.6553C31.9251 24.6553 31.0469 25.5335 31.0469 26.6169C31.0469 27.7003 31.9251 28.5786 33.0085 28.5786Z"
        fill="white"
        fillOpacity="0.3"
      />

      <path
        d="M31.8083 31.9075C31.4843 31.4733 30.8697 31.384 30.4356 31.708C30.4168 31.7221 30.3986 31.7367 30.3808 31.7521C28.9938 32.7651 27.1108 32.7651 25.7238 31.7521C25.314 31.3978 24.6945 31.4429 24.3404 31.8527C23.9861 32.2626 24.0312 32.882 24.441 33.2362C24.4587 33.2515 24.477 33.2662 24.4958 33.2802C26.5953 34.8849 29.5093 34.8849 31.6088 33.2802C32.043 32.9562 32.1323 32.3416 31.8083 31.9075Z"
        fill="white"
        fillOpacity="0.3"
      />
    </svg>,
    <svg
      width="56"
      height="57"
      viewBox="0 0 56 57"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <ellipse
        cx="28"
        cy="28.5"
        rx="28"
        ry="28.5"
        fill="white"
        fillOpacity="0.1"
      />
      <path
        d="M28.052 14.8467C20.4682 14.8467 14.3203 20.9945 14.3203 28.5783C14.3203 36.1621 20.4682 42.31 28.052 42.31C35.6357 42.31 41.7836 36.1621 41.7836 28.5783C41.7756 20.9979 35.6324 14.8548 28.052 14.8467ZM28.052 40.3483C21.5516 40.3483 16.282 35.0787 16.282 28.5783C16.282 22.0779 21.5516 16.8083 28.052 16.8083C34.5524 16.8083 39.822 22.0779 39.822 28.5783C39.8149 35.0758 34.5495 40.3413 28.052 40.3483Z"
        fill="white"
        fillOpacity="0.3"
      />
      <path
        d="M31.8083 31.9075C31.4843 31.4733 30.8697 31.384 30.4356 31.708C30.4168 31.7221 30.3986 31.7367 30.3808 31.7521C28.9938 32.7651 27.1108 32.7651 25.7238 31.7521C25.314 31.3978 24.6945 31.4429 24.3404 31.8527C23.9861 32.2626 24.0312 32.882 24.441 33.2362C24.4587 33.2515 24.477 33.2662 24.4958 33.2802C26.5953 34.8849 29.5093 34.8849 31.6088 33.2802C32.043 32.9562 32.1323 32.3416 31.8083 31.9075Z"
        fill="white"
        fillOpacity="0.3"
      />
      <path
        d="M25.0116 23.3813C24.6124 23.3813 24.2465 23.5078 23.9239 23.7573C23.6146 23.9965 23.4087 24.3011 23.2875 24.5227C23.1663 24.3011 22.9604 23.9965 22.6511 23.7573C22.3285 23.5078 21.9626 23.3813 21.5634 23.3813C20.4494 23.3813 19.6094 24.2925 19.6094 25.5008C19.6094 26.8061 20.6574 27.6993 22.244 29.0513C22.5134 29.2809 22.8188 29.5412 23.1362 29.8187C23.178 29.8554 23.2318 29.8755 23.2875 29.8755C23.3432 29.8755 23.397 29.8554 23.4388 29.8187C23.7563 29.5411 24.0616 29.2809 24.3312 29.0511C25.9176 27.6993 26.9656 26.8061 26.9656 25.5008C26.9656 24.2925 26.1256 23.3813 25.0116 23.3813Z"
        fill="white"
        fillOpacity="0.3"
      />
      <path
        d="M34.5507 23.3813C34.1515 23.3813 33.7855 23.5078 33.4629 23.7573C33.1537 23.9965 32.9478 24.3011 32.8266 24.5227C32.7053 24.3011 32.4994 23.9965 32.1902 23.7573C31.8676 23.5078 31.5016 23.3813 31.1024 23.3813C29.9885 23.3813 29.1484 24.2925 29.1484 25.5008C29.1484 26.8061 30.1965 27.6993 31.783 29.0513C32.0524 29.2809 32.3578 29.5412 32.6752 29.8187C32.7171 29.8554 32.7708 29.8755 32.8266 29.8755C32.8823 29.8755 32.936 29.8554 32.9779 29.8187C33.2953 29.5411 33.6007 29.2809 33.8703 29.0511C35.4567 27.6993 36.5047 26.8061 36.5047 25.5008C36.5047 24.2925 35.6646 23.3813 34.5507 23.3813Z"
        fill="white"
        fillOpacity="0.3"
      />
    </svg>,
  ],
  smilesFull: [
    <svg
      width="56"
      height="57"
      viewBox="0 0 56 57"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <ellipse cx="28" cy="28.5" rx="28" ry="28.5" fill="#6229BF" />
      <path
        d="M27.7316 15C20.1479 15 14 21.1479 14 28.7316C14 36.3154 20.1479 42.4633 27.7316 42.4633C35.3154 42.4633 41.4633 36.3154 41.4633 28.7316C41.4552 21.1513 35.3121 15.0081 27.7316 15ZM27.7316 40.5016C21.2312 40.5016 15.9616 35.232 15.9616 28.7316C15.9616 22.2312 21.2312 16.9616 27.7316 16.9616C34.232 16.9616 39.5016 22.2312 39.5016 28.7316C39.4946 35.2292 34.2292 40.4946 27.7316 40.5016Z"
        fill="white"
        fillOpacity="0.3"
      />
      <circle cx="28" cy="29" r="14" fill="white" />
      <path
        d="M23.1999 30.5786C24.2833 30.5786 25.1616 29.7003 25.1616 28.6169C25.1616 27.5335 24.2833 26.6553 23.1999 26.6553C22.1165 26.6553 21.2383 27.5335 21.2383 28.6169C21.2383 29.7003 22.1165 30.5786 23.1999 30.5786Z"
        fill="#6229BF"
      />
      <path
        d="M33.0085 30.5786C34.0919 30.5786 34.9702 29.7003 34.9702 28.6169C34.9702 27.5335 34.0919 26.6553 33.0085 26.6553C31.9251 26.6553 31.0469 27.5335 31.0469 28.6169C31.0469 29.7003 31.9251 30.5786 33.0085 30.5786Z"
        fill="#6229BF"
      />
      <path
        d="M24.3519 36.075C24.6759 36.5091 25.2904 36.5984 25.7246 36.2744C25.7434 36.2604 25.7616 36.2457 25.7793 36.2304C27.1664 35.2173 29.0493 35.2173 30.4364 36.2304C30.8462 36.5846 31.4656 36.5395 31.8198 36.1297C32.1741 35.7199 32.1289 35.1004 31.7191 34.7462C31.7014 34.7309 31.6831 34.7162 31.6643 34.7022C29.5649 33.0975 26.6508 33.0975 24.5514 34.7022C24.1172 35.0262 24.0279 35.6408 24.3519 36.075Z"
        fill="#6229BF"
      />
      <rect
        x="22.125"
        y="22.6514"
        width="5.38653"
        height="1.66665"
        rx="0.833325"
        transform="rotate(33.2392 22.125 22.6514)"
        fill="#6229BF"
      />
      <rect
        width="5.38653"
        height="1.66665"
        rx="0.833325"
        transform="matrix(-0.83639 0.548135 0.548135 0.83639 33.5312 22.6514)"
        fill="#6229BF"
      />
    </svg>,
    <svg
      width="56"
      height="57"
      viewBox="0 0 56 57"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <ellipse cx="28" cy="28.5" rx="28" ry="28.5" fill="#6229BF" />
      <path
        d="M28.341 14.8467C20.7572 14.8467 14.6094 20.9945 14.6094 28.5783C14.6094 36.1621 20.7572 42.31 28.341 42.31C35.9248 42.31 42.0727 36.1621 42.0727 28.5783C42.0646 20.9979 35.9215 14.8548 28.341 14.8467ZM28.341 40.3483C21.8406 40.3483 16.571 35.0787 16.571 28.5783C16.571 22.0779 21.8406 16.8083 28.341 16.8083C34.8414 16.8083 40.111 22.0779 40.111 28.5783C40.104 35.0758 34.8385 40.3413 28.341 40.3483Z"
        fill="#6229BF"
      />
      <circle cx="28" cy="29" r="14" fill="white" />
      <path
        d="M23.4343 28.5781C24.5177 28.5781 25.396 27.6998 25.396 26.6164C25.396 25.533 24.5177 24.6548 23.4343 24.6548C22.3509 24.6548 21.4727 25.533 21.4727 26.6164C21.4727 27.6998 22.3509 28.5781 23.4343 28.5781Z"
        fill="#6229BF"
      />
      <path
        d="M33.2468 28.5781C34.3302 28.5781 35.2085 27.6998 35.2085 26.6164C35.2085 25.533 34.3302 24.6548 33.2468 24.6548C32.1634 24.6548 31.2852 25.533 31.2852 26.6164C31.2852 27.6998 32.1634 28.5781 33.2468 28.5781Z"
        fill="#6229BF"
      />
      <path
        d="M24.5863 34.075C24.9102 34.5091 25.5248 34.5984 25.959 34.2744C25.9777 34.2604 25.996 34.2457 26.0137 34.2304C27.4008 33.2173 29.2837 33.2173 30.6707 34.2304C31.0806 34.5846 31.7 34.5395 32.0542 34.1297C32.4084 33.7199 32.3633 33.1004 31.9535 32.7462C31.9358 32.7309 31.9175 32.7162 31.8987 32.7022C29.7993 31.0975 26.8852 31.0975 24.7857 32.7022C24.3516 33.0262 24.2622 33.6408 24.5863 34.075Z"
        fill="#6229BF"
      />
    </svg>,
    <svg
      width="57"
      height="57"
      viewBox="0 0 57 57"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="28.5" cy="28.5" r="28.5" fill="#6229BF" />
      <circle cx="29" cy="29" r="14" fill="white" />
      <path
        d="M23.6765 29.5786C24.7599 29.5786 25.6381 28.7003 25.6381 27.6169C25.6381 26.5335 24.7599 25.6553 23.6765 25.6553C22.5931 25.6553 21.7148 26.5335 21.7148 27.6169C21.7148 28.7003 22.5931 29.5786 23.6765 29.5786Z"
        fill="#6229BF"
      />
      <path
        d="M33.4812 29.5786C34.5646 29.5786 35.4428 28.7003 35.4428 27.6169C35.4428 26.5335 34.5646 25.6553 33.4812 25.6553C32.3978 25.6553 31.5195 26.5335 31.5195 27.6169C31.5195 28.7003 32.3978 29.5786 33.4812 29.5786Z"
        fill="#6229BF"
      />
      <rect
        x="23.6953"
        y="32.8008"
        width="9.77002"
        height="2.18389"
        rx="1.09194"
        fill="#6229BF"
      />
    </svg>,
    <svg
      width="57"
      height="57"
      viewBox="0 0 57 57"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="28.5" cy="28.5" r="28.5" fill="#6229BF" />
      <path
        d="M42 29C42 36.732 35.732 43 28 43C20.268 43 14 36.732 14 29C14 21.268 20.268 15 28 15C35.732 15 42 21.268 42 29Z"
        fill="white"
      />
      <path
        d="M22.7527 28.5786C23.8717 28.5786 24.7788 27.6715 24.7788 26.5525C24.7788 25.4335 23.8717 24.5264 22.7527 24.5264C21.6337 24.5264 20.7266 25.4335 20.7266 26.5525C20.7266 27.6715 21.6337 28.5786 22.7527 28.5786Z"
        fill="#6229BF"
      />
      <path
        d="M32.8816 28.5791C34.0006 28.5791 34.9077 27.672 34.9077 26.553C34.9077 25.434 34.0006 24.5269 32.8816 24.5269C31.7626 24.5269 30.8555 25.434 30.8555 26.553C30.8555 27.672 31.7626 28.5791 32.8816 28.5791Z"
        fill="#6229BF"
      />
      <path
        d="M31.6983 32.002C31.3637 31.5536 30.7289 31.4613 30.2805 31.796C30.2611 31.8105 30.2423 31.8256 30.224 31.8414C28.7913 32.8878 26.8465 32.8878 25.4138 31.8414C24.9905 31.4756 24.3508 31.5221 23.9849 31.9454C23.619 32.3687 23.6656 33.0085 24.0889 33.3744C24.1072 33.3902 24.1261 33.4053 24.1455 33.4198C26.314 35.0773 29.3238 35.0773 31.4923 33.4198C31.9408 33.0852 32.033 32.4504 31.6983 32.002Z"
        fill="#6229BF"
      />
    </svg>,
    <svg
      width="56"
      height="57"
      viewBox="0 0 56 57"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <ellipse cx="28" cy="28.5" rx="28" ry="28.5" fill="#6229BF" />
      <path
        d="M28.052 14.8467C20.4682 14.8467 14.3203 20.9945 14.3203 28.5783C14.3203 36.1621 20.4682 42.31 28.052 42.31C35.6357 42.31 41.7836 36.1621 41.7836 28.5783C41.7756 20.9979 35.6324 14.8548 28.052 14.8467ZM28.052 40.3483C21.5516 40.3483 16.282 35.0787 16.282 28.5783C16.282 22.0779 21.5516 16.8083 28.052 16.8083C34.5524 16.8083 39.822 22.0779 39.822 28.5783C39.8149 35.0758 34.5495 40.3413 28.052 40.3483Z"
        fill="#6229BF"
      />
      <path
        d="M42 29C42 36.732 35.732 43 28 43C20.268 43 14 36.732 14 29C14 21.268 20.268 15 28 15C35.732 15 42 21.268 42 29Z"
        fill="white"
      />
      <path
        d="M31.8083 32.9075C31.4843 32.4733 30.8697 32.384 30.4356 32.708C30.4168 32.7221 30.3986 32.7367 30.3808 32.7521C28.9938 33.7651 27.1108 33.7651 25.7238 32.7521C25.314 32.3978 24.6945 32.4429 24.3404 32.8527C23.9861 33.2626 24.0312 33.882 24.441 34.2362C24.4587 34.2515 24.477 34.2662 24.4958 34.2802C26.5953 35.8849 29.5093 35.8849 31.6088 34.2802C32.043 33.9562 32.1323 33.3416 31.8083 32.9075Z"
        fill="#6229BF"
      />
      <path
        d="M25.0116 24.3813C24.6124 24.3813 24.2465 24.5078 23.9239 24.7573C23.6146 24.9965 23.4087 25.3011 23.2875 25.5227C23.1663 25.3011 22.9604 24.9965 22.6511 24.7573C22.3285 24.5078 21.9626 24.3813 21.5634 24.3813C20.4494 24.3813 19.6094 25.2925 19.6094 26.5008C19.6094 27.8061 20.6574 28.6993 22.244 30.0513C22.5134 30.2809 22.8188 30.5412 23.1362 30.8187C23.178 30.8554 23.2318 30.8755 23.2875 30.8755C23.3432 30.8755 23.397 30.8554 23.4388 30.8187C23.7563 30.5411 24.0616 30.2809 24.3312 30.0511C25.9176 28.6993 26.9656 27.8061 26.9656 26.5008C26.9656 25.2925 26.1256 24.3813 25.0116 24.3813Z"
        fill="#6229BF"
      />
      <path
        d="M34.5507 24.3813C34.1515 24.3813 33.7855 24.5078 33.4629 24.7573C33.1537 24.9965 32.9478 25.3011 32.8266 25.5227C32.7053 25.3011 32.4994 24.9965 32.1902 24.7573C31.8676 24.5078 31.5016 24.3813 31.1024 24.3813C29.9885 24.3813 29.1484 25.2925 29.1484 26.5008C29.1484 27.8061 30.1965 28.6993 31.783 30.0513C32.0524 30.2809 32.3578 30.5412 32.6752 30.8187C32.7171 30.8554 32.7708 30.8755 32.8266 30.8755C32.8823 30.8755 32.936 30.8554 32.9779 30.8187C33.2953 30.5411 33.6007 30.2809 33.8703 30.0511C35.4567 28.6993 36.5047 27.8061 36.5047 26.5008C36.5047 25.2925 35.6646 24.3813 34.5507 24.3813Z"
        fill="#6229BF"
      />
    </svg>,
  ],
};

export const IconBackToAppButton = ({ bgColor }: { bgColor: string }) => {
  return (
    <svg
      width="19"
      height="16"
      viewBox="0 0 19 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.0768 8.88801C17.7728 5.81998 15.012 3.57589 11.9289 3.57589H7.36342V0.890287C7.36342 0.684033 7.24525 0.496041 7.05941 0.405804C6.87249 0.316642 6.65227 0.340275 6.49113 0.470259L1.11993 4.76722C0.992091 4.87035 0.917969 5.02397 0.917969 5.18725C0.917969 5.35054 0.992091 5.50415 1.11885 5.60728L6.49006 9.90425C6.58781 9.98159 6.70706 10.0213 6.8263 10.0213C6.90579 10.0213 6.98528 10.0042 7.05941 9.9687C7.24525 9.87846 7.36342 9.69047 7.36342 9.48422V6.79861H12.0428C13.4479 6.79861 14.7284 7.8084 14.8691 9.20599C15.0314 10.8131 13.7713 12.1698 12.1975 12.1698H7.90054C7.60405 12.1698 7.36342 12.4105 7.36342 12.7069V14.8554C7.36342 15.1519 7.60405 15.3925 7.90054 15.3925H12.1975C15.649 15.3925 18.4238 12.4094 18.0768 8.88801Z"
        fill={bgColor}
      />
    </svg>
  );
};

export const IconCommentButton = ({ bgColor }: { bgColor: string }) => {
  return (
    <svg
      width="23"
      height="21"
      viewBox="0 0 23 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.5109 18.248C22.4895 18.2359 20.5065 17.1107 19.0985 15.555C20.0944 13.973 20.6184 12.1571 20.6184 10.2716C20.6184 7.61704 19.5846 5.12129 17.7076 3.24422C15.8305 1.36731 13.3347 0.333496 10.6803 0.333496C8.02573 0.333496 5.52999 1.36731 3.65308 3.24422C1.776 5.12129 0.742188 7.61704 0.742188 10.2716C0.742188 12.926 1.776 15.4218 3.65308 17.2989C5.52999 19.1759 8.02573 20.2096 10.6803 20.2096C12.3746 20.2096 14.0338 19.7793 15.5042 18.962C18.4019 20.573 22.2069 19.4919 22.3738 19.4432C22.6259 19.3695 22.8093 19.152 22.8392 18.8911C22.8692 18.6301 22.7399 18.3769 22.5109 18.248ZM15.8664 17.6572C15.6558 17.5196 15.3847 17.5155 15.1698 17.6466C13.8212 18.4694 12.2687 18.9045 10.6803 18.9045C5.92017 18.9045 2.04731 15.0318 2.04731 10.2716C2.04731 5.51131 5.92017 1.63862 10.6803 1.63862C15.4405 1.63862 19.3132 5.51131 19.3132 10.2716C19.3132 12.058 18.7721 13.7723 17.748 15.2292C17.5806 15.4675 17.5912 15.788 17.7743 16.0145C18.5846 17.0176 19.63 17.8722 20.4625 18.4701C19.1638 18.6117 17.2963 18.5905 15.8664 17.6572Z"
        fill={bgColor}
      />
      <path
        d="M15.1023 12.5195H6.25412C5.89361 12.5195 5.60156 12.8117 5.60156 13.1721C5.60156 13.5324 5.89361 13.8247 6.25412 13.8247H15.1023C15.4626 13.8247 15.7549 13.5324 15.7549 13.1721C15.7549 12.8117 15.4626 12.5195 15.1023 12.5195Z"
        fill={bgColor}
      />
      <path
        d="M15.1023 9.61865H6.25412C5.89361 9.61865 5.60156 9.91087 5.60156 10.2714C5.60156 10.6317 5.89361 10.9239 6.25412 10.9239H15.1023C15.4626 10.9239 15.7549 10.6317 15.7549 10.2714C15.7549 9.91087 15.4626 9.61865 15.1023 9.61865Z"
        fill={bgColor}
      />
      <path
        d="M15.1023 6.71826H6.25412C5.89361 6.71826 5.60156 7.01048 5.60156 7.37082C5.60156 7.73133 5.89361 8.02338 6.25412 8.02338H15.1023C15.4626 8.02338 15.7549 7.73133 15.7549 7.37082C15.7549 7.01048 15.4626 6.71826 15.1023 6.71826Z"
        fill={bgColor}
      />
    </svg>
  );
};

export const IconVideoButton = ({ bgColor }: { bgColor: string }) => {
  return (
    <svg
      width="27"
      height="16"
      viewBox="0 0 27 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.947687 1.88235C0.948624 1.23294 1.47464 0.706925 2.12405 0.705988H16.9233C17.5727 0.706925 18.0989 1.23316 18.0997 1.88267V13.62C18.0989 14.2694 17.5729 14.7955 16.9237 14.7967C16.9235 14.7967 16.9232 14.7967 16.923 14.7967H2.12437C2.12415 14.7967 2.12393 14.7967 2.12372 14.7967C1.47445 14.7956 0.948624 14.2696 0.947687 13.6203V1.88235Z"
        stroke={bgColor}
        strokeWidth="1.21569"
      />
      <path
        d="M20.8228 10.006C20.6277 9.89948 20.5063 9.69487 20.5063 9.47252V6.06711C20.5063 5.84476 20.6277 5.64015 20.8228 5.5336L24.5215 3.5143C24.9265 3.29315 25.4206 3.58631 25.4206 4.04781V11.4918C25.4206 11.9533 24.9265 12.2465 24.5215 12.0253L20.8228 10.006Z"
        stroke={bgColor}
        strokeWidth="1.21569"
      />
    </svg>
  );
};

export const SendIconButton = ({ bgColor }: { bgColor: string }) => {
  return (
    <svg
      width="23"
      height="20"
      viewBox="0 0 23 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        stroke={bgColor}
        d="M22.9257 0.794751C23.0348 0.286914 22.5369 -0.141288 22.0513 0.0440318L0.984278 8.0862C0.730731 8.18301 0.563026 8.42602 0.562501 8.6974C0.561977 8.96883 0.728721 9.21249 0.981918 9.31027L6.90003 11.5959V18.7951C6.90003 19.0988 7.10856 19.3627 7.40402 19.433C7.69752 19.5028 8.00356 19.3631 8.14115 19.0902L10.5888 14.233L16.562 18.666C16.9254 18.9356 17.4476 18.7641 17.5799 18.3308C23.1569 0.0454748 22.9158 0.840687 22.9257 0.794751ZM17.7254 3.09892L7.46272 10.4076L3.04705 8.70225L17.7254 3.09892ZM8.21126 11.4843L17.1568 5.11361C9.45928 13.234 9.8613 12.8066 9.82773 12.8518C9.77786 12.9189 9.91449 12.6574 8.21126 16.0373V11.4843ZM16.5969 17.059L11.339 13.157L20.8459 3.12777L16.5969 17.059Z"
        fill="white"
      />
    </svg>
  );
};

export const ChatIconButton = ({ bgColor }: { bgColor: string }) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.3278 3.98779H3.89541C3.59952 3.98779 3.35938 4.22794 3.35938 4.52382C3.35938 4.81971 3.59952 5.05986 3.89541 5.05986H10.3278C10.6237 5.05986 10.8638 4.81971 10.8638 4.52382C10.8638 4.22794 10.6237 3.98779 10.3278 3.98779Z"
        fill={bgColor}
      />
      <path
        d="M8.18366 6.13281H3.89541C3.59952 6.13281 3.35938 6.37295 3.35938 6.66884C3.35938 6.96473 3.59952 7.20488 3.89541 7.20488H8.18366C8.47955 7.20488 8.71969 6.96473 8.71969 6.66884C8.71969 6.37295 8.47955 6.13281 8.18366 6.13281Z"
        fill={bgColor}
      />
      <path
        d="M11.9372 0.772461H2.28866C1.10617 0.772461 0.144531 1.7341 0.144531 2.91659V13.6372C0.144531 13.8452 0.264602 14.035 0.453285 14.1229C0.525114 14.1561 0.603374 14.1732 0.680563 14.1732C0.80385 14.1732 0.926065 14.1304 1.02362 14.0489L4.09079 11.4931H11.9372C13.1197 11.4931 14.0813 10.5315 14.0813 9.34896V2.91659C14.0813 1.7341 13.1197 0.772461 11.9372 0.772461ZM13.0093 9.34896C13.0093 9.93967 12.529 10.421 11.9372 10.421H3.89675C3.77132 10.421 3.65018 10.465 3.55369 10.5454L1.21659 12.4933V2.91659C1.21659 2.32588 1.69688 1.84452 2.28866 1.84452H11.9372C12.529 1.84452 13.0093 2.32588 13.0093 2.91659V9.34896Z"
        fill={bgColor}
      />
      <path
        d="M15.1542 5.06055C14.8583 5.06055 14.6182 5.30069 14.6182 5.59658C14.6182 5.89247 14.8583 6.13261 15.1542 6.13261C15.746 6.13261 16.2263 6.61397 16.2263 7.20467V16.2733L14.4166 14.826C14.3223 14.7509 14.2033 14.7091 14.0822 14.7091H6.57772C5.98594 14.7091 5.50566 14.2278 5.50566 13.637V13.101C5.50566 12.8051 5.26551 12.565 4.96963 12.565C4.67374 12.565 4.43359 12.8051 4.43359 13.101V13.637C4.43359 14.8195 5.39523 15.7812 6.57772 15.7812H13.8935L16.4268 17.8084C16.5243 17.8856 16.6433 17.9253 16.7623 17.9253C16.8406 17.9253 16.9199 17.9081 16.995 17.8728C17.1804 17.7827 17.2983 17.5951 17.2983 17.3893V7.20467C17.2983 6.02219 16.3367 5.06055 15.1542 5.06055Z"
        fill={bgColor}
      />
    </svg>
  );
};

export const ChatCloseIconButton = () => {
  return (
    <svg
      version="1.1"
      id="Camada_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 18 18"
      width="18px"
      fill="#d02a3e"
    >
      <path d="M10.3,4H3.9C3.6,4,3.4,4.2,3.4,4.5c0,0.3,0.2,0.5,0.5,0.5h6.4c0.3,0,0.5-0.2,0.5-0.5C10.9,4.2,10.6,4,10.3,4z" />
      <path d="M8.2,6.1H3.9c-0.3,0-0.5,0.2-0.5,0.5s0.2,0.5,0.5,0.5h4.3c0.3,0,0.5-0.2,0.5-0.5S8.5,6.1,8.2,6.1z" />
      <path
        d="M11.9,0.8H2.3c-1.2,0-2.1,1-2.1,2.1v10.7c0,0.2,0.1,0.4,0.3,0.5c0.1,0,0.2,0.1,0.2,0.1c0.1,0,0.2,0,0.3-0.1
	l3.1-2.6h7.8c1.2,0,2.1-1,2.1-2.1V2.9C14.1,1.7,13.1,0.8,11.9,0.8z M13,9.3c0,0.6-0.5,1.1-1.1,1.1h-8c-0.1,0-0.2,0-0.3,0.1l-2.3,1.9
	V2.9c0-0.6,0.5-1.1,1.1-1.1h9.6c0.6,0,1.1,0.5,1.1,1.1V9.3z"
      />
      <path
        d="M15.2,5.1c-0.3,0-0.5,0.2-0.5,0.5s0.2,0.5,0.5,0.5c0.6,0,1.1,0.5,1.1,1.1v9.1l-1.8-1.4
	c-0.1-0.1-0.2-0.1-0.3-0.1H6.6c-0.6,0-1.1-0.5-1.1-1.1v-0.5c0-0.3-0.2-0.5-0.5-0.5c-0.3,0-0.5,0.2-0.5,0.5v0.5c0,1.2,1,2.1,2.1,2.1
	h7.3l2.5,2c0.1,0.1,0.2,0.1,0.3,0.1c0.1,0,0.2,0,0.2-0.1c0.2-0.1,0.3-0.3,0.3-0.5V7.2C17.3,6,16.3,5.1,15.2,5.1z"
      />
    </svg>
  );
};

export const CamOnIconButton = () => {
  return <img src={CamOnIcon} alt="camera ligada" />;
};

export const CamOffIconButton = () => {
  return <img src={CamOffIcon} alt="camera desligada" />;
};

export const MicOnIconButton = () => {
  return <img src={MicOnIcon} alt="mic ligada" />;
};

export const MicOffIconButton = () => {
  return <img src={MicOffIcon} alt="mic desligado" />;
};

export const PersonIconButton = ({ bgColor }: { bgColor: string }) => {
  return (
    <>
      <svg
        width="16px"
        viewBox="0 0 17 17"
        fill="#ccc"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.2259 11.1076C13.3183 10.2 12.2379 9.52806 11.0592 9.12132C12.3216 8.25182 13.151 6.79665 13.151 5.1512C13.151 2.49473 10.9898 0.333496 8.33333 0.333496C5.67686 0.333496 3.51562 2.49473 3.51562 5.1512C3.51562 6.79665 4.34502 8.25182 5.60749 9.12132C4.42878 9.52806 3.34844 10.2 2.44079 11.1076C0.866829 12.6816 0 14.7743 0 17.0002H1.30208C1.30208 13.1231 4.45628 9.96891 8.33333 9.96891C12.2104 9.96891 15.3646 13.1231 15.3646 17.0002H16.6667C16.6667 14.7743 15.7998 12.6816 14.2259 11.1076ZM8.33333 8.66683C6.39482 8.66683 4.81771 7.08975 4.81771 5.1512C4.81771 3.21266 6.39482 1.63558 8.33333 1.63558C10.2718 1.63558 11.849 3.21266 11.849 5.1512C11.849 7.08975 10.2718 8.66683 8.33333 8.66683Z"
          fill={bgColor}
        />
      </svg>
    </>
  );
};

export const FileIcon = ({ bgColor }: { bgColor?: string }) => {
  return (
    <>
      <svg
        width="12"
        height="23"
        viewBox="0 0 12 23"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.55503 0.162109C2.53763 0.162109 0.0820312 2.8247 0.0820312 6.0983V17.3368C0.0820312 17.738 0.381321 18.0626 0.75128 18.0626C1.12099 18.0626 1.42028 17.738 1.42028 17.3368V6.0983C1.42028 3.62554 3.27473 1.61365 5.55503 1.61365C7.83556 1.61365 9.68997 3.62554 9.68997 6.0983V17.6849C9.68997 19.2934 8.48309 20.6025 6.99975 20.6025C6.98873 20.6025 6.97881 20.6089 6.96782 20.6096C6.95607 20.6089 6.94688 20.6025 6.93516 20.6025C5.45194 20.6025 4.24506 19.2934 4.24506 17.6849V10.7346C4.24506 9.95074 4.83242 9.31364 5.55503 9.31364C6.2778 9.31364 6.86519 9.95074 6.86519 10.7346V17.3368C6.86519 17.738 7.16501 18.0626 7.53423 18.0626C7.90337 18.0626 8.20344 17.738 8.20344 17.3368V10.7346C8.20344 9.1506 7.01547 7.86215 5.55503 7.86215C4.09479 7.86215 2.90681 9.15064 2.90681 10.7346V17.6849C2.90681 20.0936 4.71353 22.054 6.93512 22.054C6.94684 22.054 6.95602 22.0476 6.96778 22.0469C6.97881 22.0476 6.98873 22.054 6.99971 22.054C9.22077 22.054 11.028 20.0937 11.028 17.6849V6.0983C11.028 2.8247 8.57246 0.162109 5.55503 0.162109Z"
          fill={bgColor ? bgColor : "white"}
        />
      </svg>
    </>
  );
};
