import { useState } from "react";
import { useHistory } from "react-router";
import PessoalizeLogo from "../../assets/pessoalize/pessoalizeLoginLogo.png";
import { useAuth } from "../../hooks/auth";
import { setLsUserToken } from "../../localStorage/index";
import { request } from "../../services/request";
import { LoginContainer, LoginContent, LoginHeader } from "./styles";
import { getIpData } from "../../utils";

export const Login: React.FC = () => {
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const { verifyUserExists } = useAuth();
  const history = useHistory();
  const handleLogin = async () => {
    const response = await request({
      showLoading: true,
      showSuccessMessage: false,
      method: "POST",
      path: `users/login`,
      data: {
        login: userName.trim(),
        password: password.trim(),
        ip: await getIpData(),
      },
    }, true);
    if (response.access_token) {
      setLsUserToken(response.access_token);
      await verifyUserExists();
      history.push("/");
    }
  };

  return (
    <LoginContainer>
      <LoginContent>
        <LoginHeader>
          <img src={PessoalizeLogo} alt="Pessoalize Logo" />
          <h3>Login</h3>
        </LoginHeader>

        <input
          placeholder="Digite seu usuário"
          type="text"
          data-cy="username"
          onChange={(e) => {
            setUserName(e.target.value);
          }}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              handleLogin();
            }
          }}
        />
        <input
          placeholder="Digite sua senha"
          type="password"
          data-cy="password"
          onChange={(e) => {
            setPassword(e.target.value);
          }}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              handleLogin();
            }
          }}
        />
        <button
          onClick={handleLogin}
          data-cy="btnLogin"
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              handleLogin();
            }
          }}
        >
          Entrar
        </button>
      </LoginContent>
    </LoginContainer>
  );
};
