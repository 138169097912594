import styled, { css } from "styled-components";

export const RoomContainer = styled.div`
  background: linear-gradient(180deg, #2c1050 -18.72%, #0a070f 75.27%);
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SideBarArea = styled.div`
  position: fixed;
  right: 0;
  transition: all 0.5s linear;
  ${(props: { menuIsOpen: boolean }) =>
    !!props.menuIsOpen
      ? css`
          right: -20vw;
        `
      : css`
          right: 0;
        `};
  display: flex;
  flex-direction: column;

  & > div {
    height: 100vh;
    width: 20vw;
    background: #eeeeee;
    box-shadow: -25px 4px 50px 8px rgba(0, 0, 0, 0.25);
    overflow-y: auto;
    position: relative;
    z-index: 1000px;
  }
`;

export const WrapButton = styled.button`
  position: absolute;
  width: 55px;
  height: 164px;
  cursor: pointer;
  background: #eeeeee;
  border-radius: 10px 0px 0px 10px;

  left: -55px;
  top: 114px;

  @media (min-width: 768px) and (max-width: 1366px) {
    height: 54px;
    left: -54px;
    top: 39px;
  }
  &:hover {
    svg {
      path {
        fill: #5c27b3;
      }
    }
  }

  svg {
    transform: rotate(180deg);
  }
`;

export const SideBarContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-bottom: 100px;
`;
export const RequiredMessage = styled.span`
  color: red;
  font-size: 12px;
  width: 80%;
  text-align: center;
`;

export const ContactAgainContainer = styled.div`
  position: fixed;
  z-index: 2000;
  width: 500px;
  height: 300px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  border-radius: 15px;
  padding: 20px;
  text-align: center;
  box-shadow: -1px 1px 20px 9999px rgb(0 0 0 / 81%);
  p {
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 22px;
    color: #000000;
    margin-bottom: 18px;
  }
  div {
    display: flex;
    align-items: center;

    button {
      width: 150px;
      height: 50px;
      background-color: #5c27b3;
      border-radius: 50px;
      padding: 10px 30px;
      color: #fff;
      margin-left: 8px;
      cursor: pointer;

      &:hover {
        filter: brightness(1.2);
      }
    }
    button:first-child {
      margin-left: 0px;
    }
  }
`;

export const HeaderLogo = styled.div`
  ${(props: { backgroundLogoColor: string }) =>
    props.backgroundLogoColor
      ? `
          background-color: ${props.backgroundLogoColor};
          
        `
      : css`
          display: none;
        `}

  display:flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  img {
    max-width: 100px;
    margin: 16px 0;
    max-height: 100px;
  }
`;

export const TabbingInputContainer = styled.div`
  margin-top: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;

  #select-name h3 {
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 22px;
    color: #000000;
    margin-bottom: 18px;
  }

  .tabbing-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;

    & > div {
      border-radius: 20px;
      margin-top: 5px;
      width: 80%;
      height: fit-content;

      span {
        background: #ffffff;
        opacity: 0.75;

        width: fit-content;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-left: 8px;
      }

      p {
        font-family: Poppins;
        font-style: bold;
        font-weight: bold;
        font-size: 16px;
        line-height: 19px;
        color: #000000;
      }

      textarea {
        margin: 0;
        padding-top: 12px;
        padding-left: 4px;
        background: #ffffff;
        opacity: 0.75;

        border-radius: 0px 20px 20px 20px;
        width: 100%;
        min-height: 40px;
      }
      input {
        margin: 0;
        padding-left: 4px;
        margin-top: 5px;
        background: #ffffff;
        opacity: 0.75;
        width: 100%;
        height: 40px;
      }
    }
  }

  hr {
    margin-top: 32px;
    border: 1px solid #d2d2d2;
    width: 80%;
  }
`;

export const FinalizeButton = styled.button`
  background: #5c27b3;
  box-shadow: 0px 6px 4px rgba(0, 0, 0, 0.25);
  border-radius: 30px;
  width: 142px;
  height: 53px;
  color: #fff;
  cursor: pointer;
  position: fixed;
  bottom: 25px;
  font-size: 14px;
  font-weight: bold;
  font-family: "Poppins";
  padding-right: 30px;

  &:hover {
    opacity: 0.8;
  }

  svg {
    position: absolute;
    right: 17px;
  }

  #spinner {
    min-width: 15px;
    min-height: 15px;
    border: 3px solid #810ad1;
    border-right: 3px solid orange;
    border-radius: 50%;
    animation: spinner 1s linear infinite;
    max-width: 15px;
    position: absolute;
    right: 19px;
    top: 17px;
    padding: 0;
  }

  @keyframes spinner {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;

export const ButtonsContainerBottom = styled.div`
  display: flex;
  width: 100%;
  max-width: 320px;
  justify-content: space-evenly;
  flex-direction: row;
  flex-wrap: nowrap;
  margin: 0 auto;

  div {
    width: 48px;
    height: 48px;
    margin: 0;
    padding: 0;
    min-width: auto;

    span {
      display: none;
    }
  }

  @media (max-width: 600px) {
    display: block;
  }
`;

export const SwitchButtonContainer = styled.div`
  display: flex;
  padding: 8px 0 0;

  button {
    margin-right: 20px;
  }
`;

export const VideoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 74vw;
  transition: all 0.5s linear;
  ${(props: { menuIsOpen: boolean }) =>
    !props.menuIsOpen &&
    css`
      margin-right: 17vw;
    `}

  .container-video {
    display: flex;
    width: 100%;
    height: 100vh;
    align-items: center;
  }
`;

export const AttendantPaused = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 800;
  top: 0px;
  left: 0px;
  border-radius: 10px;
  background: #ccc;
`;

export const VideoContent = styled.div`
  position: relative;
  width: 100%;

  div[id*="-visitant"] {
    width: 100%;
    height: 100%;
    display: flex;
    align-content: center;
    justify-content: center;
  }

  #participants {
    width: 100%;
    height: auto;
    border-radius: 10px;
    margin-bottom: 15px;
    overflow: hidden;

    video {
      width: 100%;
      height: auto;
      border-radius: 10px;
      transform: scaleX(-1);
    }

    .icon-avatar {
      width: 100%;
      height: 100%;
      min-height: 40vh;
      background-color: #f0f0f0;
      z-index: 1;
      border-radius: 22px;
      top: 0;

      div {
        margin: -18px auto !important;
      }
    }

    h3 {
      text-align: center;
      color: #000;
      z-index: 10;
      position: relative;
      padding-left: 100px;
      padding-top: 65px;
      font-size: 24px;
    }
  }
  #local-video {
    position: absolute;
    z-index: 500;
    top: 15px;
    left: 16px;
    border-radius: 10px;
    max-width: 220px;
    @media (min-width: 768px) and (max-width: 1366px) {
    }
    video {
      border-radius: 10px;
      width: 100%;
      transform: scaleX(-1);
    }

    &.chatOpenLocal {
      top: 15px;
    }
  }
`;

export const InviteParticipantButton = styled.button`
  width: 48px;
  height: 48px;
  padding: 10px;
  text-align: center;
  border-radius: 60px;
  background-color: #fff;
  color: #000;
  font-weight: bold;
  font-family: "Poppins";
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-evenly;

  svg {
    width: 24px;
    margin-top: 2px;
    path {
      fill: #5c27b3;
    }
  }
`;

export const AreaTopButtons = styled.div`
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end;
  width: 100%;
`;

export const LabelTabbing = styled.div`
 width: 100%;
 font-size: 16px;
 font-weight: 700;
`
